import React from 'react';
import Cookies from "js-cookie";
import axios from 'axios';

// export function JWTRegister(data,validate) {
//     let link = 'auth/signup';
//     if(!data.acceptedTerms){
//         validate('You must accept our Terms Of Service');
//         return;
//     }
//         axios.post(link, data).then(function (response) {
//         console.log(response);
//         //currentDateTime.setTime(currentDateTime.getTime() + (response.data.expires_in*3600));
//         //console.log(currentDateTime.toGMTString() );
//         Cookies.set('user', response.data.access_token,{ expires:3600 });
//         Cookies.set('plan', response.data.plan ? response.data.plan : "null");
//         Cookies.set('user-name', response.data.user_name);
//
//         window.location.href = '/dash';
//         // console.log(response)
//     }).catch(function (error) {
//         console.log(error.response.data.errors)
//         let errorsObject = error.response.data.errors;
//         if(Object.keys(errorsObject).length > 0){
//             let firstError = errorsObject[Object.keys(errorsObject)[0]];
//             validate(firstError);
//         }else
//             validate('There was an error signing you up, please come back later');
//         return;
//
//         var errorEmail = error.response.data.errors.email[0];
//         let message = errorEmail;
//
//         if(typeof errorEmail === 'undefined'){
//             if(typeof error.response.data.message != 'undefined'){
//                 message = error.response.data.message;
//             }else{
//                 message = "Please retry later";
//             }
//         }
//         validate(message);
//     });
// }


export function JWTLogin(dataLogin, is_ouath=false, validate) {
    let link = `${window.api_url}login`;
    axios.post(link, dataLogin).then(function (response) {
        if(!response.data.status){
            validate(response.data.message);
        }else{
            let expireTokenIn = response.data.expires_in;
            Cookies.set('user', response.data.access_token, { expires:expireTokenIn });
            Cookies.set('plan', response.data.plan ? response.data.plan : "null");
            Cookies.set('user-name', response.data.user_name);
            window.location.href = '/admin';
        }
    }).catch(function (error) {
        validate('There was an error logging you in, please come back later');
    });
}

export function JWTIs_connected(){
    const token = Cookies.get('user');
    if(token == null || token == undefined || typeof token == 'undefined' || token == 'undefined') {
        return false;
    }
    return true;
}

export function JWT_hasPlan(){
    const plan = Cookies.get('plan');
    if(plan && plan !== 'null') {
        return true;
    }
    return false;
}

export function GetToken(){
    let token = "";
    if(JWTIs_connected()){
        token = Cookies.get('user');
    }else{
        window.location ='/auth/login';
    }
    return token;
}


export function RefreshToken() {
    let link = '/api/refresh';
    axios.post(link).then(function (response) {
        let expireTokenIn = response.data.expires_in;
        //currentDateTime.setTime(currentDateTime.getTime() + (response.data.expires_in*3600));
        //console.log(currentDateTime.toGMTString() );
        Cookies.set('user', response.data.access_token,{ expires:expireTokenIn });

    }).catch(function (error) {
        let message = error.response.data.message;
        alert(message);
        window.location.href = '/login';
    });
}



export function resetPassword(email, validate) {
    let link = '/api/password/create';
    axios.post(link, email).then(function (response) {
        if(!response.data.status)
            validate(response.data.message);
        validate(response.data.message, true);
        console.log(response);
    }).catch(function (error) {
        //let message = error.response.data.message;
        validate('There was an error while resetting your password, please come back later');
    });
}

export function checkToken(token, validate, callb) {
    let link = '/api/password/find/'+token;
    axios.get(link).then(function (response) {
        if(!response.data.status)
            validate('There was an error while resetting your password, please come back later');

        callb(response.data);
    }).catch(function (error) {
        //let message = error.response.data.message;
        validate('There was an error while resetting your password, please come back later');
    });
}

export function setNewPassword(data, validate) {
    let link = '/api/password/reset';
    axios.post(link, data).then(function (response) {
        if(response.data.status){
            Cookies.set('user', response.data.access_token);
            Cookies.set('plan', response.data.plan ? response.data.plan : "null");
            Cookies.set('user-name', response.data.user_name);
            window.location.href = '/dash';
        }
        else
            validate(response.data.message);
    }).catch(function (error) {
        validate('There was an error while setting your password, please come back later');
    });
}


