/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardImg,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col, Modal, InputGroup, InputGroupAddon, InputGroupText, Table, Badge
} from "reactstrap";
// core components
import ProfileHeader from "components/Headers/ProfileHeader.js";
import {HttpConfig} from "../../tools/Http";
import Cookies from "js-cookie";
import classnames from "classnames";
import AuthHeader from "../../components/Headers/AuthHeader";
import {Link} from "react-router-dom";
import moment from "moment";
const queryString = require('query-string');

class Profile extends React.Component {

    constructor(props) {
        super(props);
        const src = null;
        let params = queryString.parse(this.props.location.search);

        this.state = {
            id: params['id'],
            user: {},
            associates: [],
            history: [],
            editables: [],
            preview:null,
            defaultModal:null,
        }

    }

    componentDidMount() {
        this.getData();
    }

    getData(){
       this.getInfo();
       this.getHistory();
    }

    render() {
        return (
            <>
                <ProfileHeader userName={this.state.user.name}/>
                <Container className="mt--6" fluid>
                    <Row>
                        <Col className="order-xl-2" xl="4">
                            <Card className="card-profile">
                                <CardImg
                                    alt="..."
                                    src={require("assets/img/theme/img-1-1000x600.jpg")}
                                    top
                                />
                                <Row className="justify-content-center">
                                    <Col className="order-lg-2" lg="3">
                                        <div className="card-profile-image">
                                            <img
                                                alt="..."
                                                className={"rounded-circle"}
                                                src={this.state.user.avatar}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                    <div className="d-flex justify-content-between">
                                    </div>
                                </CardHeader>
                                <CardBody className="pt-0">
                                    <Row>
                                        <div className="col">
                                            <div className="card-profile-stats d-flex justify-content-center">
                                                <div>
                                                    <span className="heading">{this.state.user.pub}</span>
                                                    <span className="description">Published Apps</span>
                                                </div>
                                                <div>
                                                    <span className="heading">{this.state.user.pen}</span>
                                                    <span className="description">Pending Apps</span>
                                                </div>
                                                <div>
                                                    <span className="heading">{this.state.user.sus}</span>
                                                    <span className="description">Suspended Apps</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                    <div className="text-center">
                                        <h5 className="h3">
                                            {this.state.user.username}
                                        </h5>
                                        <div>
                                            <i className="ni education_hat mr-2" />
                                            User since : {this.state.user.since}
                                        </div>
                                        <div style={{height:10}}/>
                                    </div>
                                </CardBody>
                            </Card>

                        </Col>
                        <Col className="order-xl-1" xl="8">
                            <Row>
                                <Col lg="6">
                                    <Card className="bg-gradient-danger border-0">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle className="text-uppercase text-muted mb-0 text-white"
                                                        tag="h5">
                                                        Total Requests
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0 text-white">
                                                        {this.state.user.num_requests}
                                                    </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                                                        <i className="ni ni-active-40" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                                                <span className="text-nowrap text-light">
                                                Consumed through requests
                                                </span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="6">
                                    <Card className="bg-gradient-success border-0" tag="h5">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle className="text-uppercase text-muted mb-0 text-white">
                                                        {this.state.user.expires ?  'Plan Expires at' : 'No Plan'}
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0 text-white">
                                                        {this.state.user.expires ? moment(this.state.user.expires).format("dd, MMMM Do YYYY, hh:mm") : ""}
                                                    </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                                                        <i className="ni ni-spaceship" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                                                <span className="text-white mr-2">
                                                    {this.state.user.expires ? moment(this.state.user.expires).diff(moment(),'days') : ""}
                                                </span>
                                                <span className="text-nowrap text-light">
                                                     {this.state.user.left ? 'days left' : 'No payments'}
                                                </span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Card>
                                <CardHeader>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <h6 className="heading-small text-muted mb-4">
                                            My Information
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-username">
                                                            Full Name
                                                        </label>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-user" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="Your name"
                                                                type="text"
                                                                onFocus={e => this.setState({ yourName: true })}
                                                                onBlur={e => this.setState({ yourName: false })}
                                                                onChange={e => {
                                                                    // let o = this.state.me;
                                                                    // o.name = e.target.value;
                                                                    // this.setState({me:o});
                                                                }}
                                                                value={this.state.user.name}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-email"
                                                        >
                                                            Phone Number
                                                        </label>
                                                        <InputGroup>
                                                            <Input
                                                                placeholder="Phone Number"
                                                                type="text"
                                                                onFocus={e => this.setState({ phone: true })}
                                                                onBlur={e => this.setState({ phone: false })}
                                                                value={this.state.user.phone}
                                                                onChange={e => {
                                                                    // let o = this.state.me;
                                                                    // o.phone = e.target.value;
                                                                    // this.setState({me:o});
                                                                }}/>
                                                            <InputGroupAddon addonType="append">
                                                                <InputGroupText>
                                                                    <i className="fas fa-phone" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label className="form-control-label" htmlFor="input-first-name">
                                                            Email Address
                                                        </label>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-envelope" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="Email address"
                                                                type="email"
                                                                value={this.state.user.email}
                                                                onFocus={e =>
                                                                    this.setState({ emailAddress: true })
                                                                }
                                                                onBlur={e =>
                                                                    this.setState({ emailAddress: false })
                                                                }
                                                                onChange={e => {
                                                                    // let o = this.state.me;
                                                                    // o.email = e.target.value;
                                                                    // this.setState({me:o});
                                                                }}/>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-password">
                                                            Address
                                                        </label>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-pin" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="Address"
                                                                type="Address"
                                                                value={this.state.user.address}
                                                                onFocus={e =>
                                                                    this.setState({ address: true })
                                                                }
                                                                onBlur={e =>
                                                                    this.setState({ address: false })
                                                                }
                                                                onChange={e => {
                                                                    // let o = this.state.me;
                                                                    // o.password = e.target.value;
                                                                    // this.setState({me:o});
                                                                }}/>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        {this.getHistoryTable()}
                    </Row>
                </Container>
            </>
        );
    }
    getInfo() {
        HttpConfig().get("users/info/"+this.state.id)
            .then(res => {
                if (res.data.status) {
                    this.setState({user:res.data.user});
                }
                else {

                }
            }).catch(err => {
            console.log(err);
        })
    }

    getHistoryTable(){
        return (
            <Col>
            <Card>
                <CardHeader className="border-0">
                    <Row>
                        <Col xs="6">
                            <h3 className="mb-0">The words the user is searching for</h3>
                        </Col>
                    </Row>
                </CardHeader>

                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                        <th>id</th>
                        <th>Keywords</th>
                        <th>action</th>
                    </tr>
                    </thead>
                    <tbody>
                        {this.state.history.map((el, index) => {
                            return (
                                <tr>
                                    <td className="table-user">
                                        <span className="text-muted">{index+1}</span>
                                    </td>
                                    <td className="table-user">
                                        <span className="text-muted">{el}</span>
                                    </td>
                                    <td>
                                        <Button className={'btn btn-sm btn-outline-success'} title={'Copy Keyword'} onClick={e => {navigator.clipboard.writeText(el);}}><i className="far fa-clipboard fa-lg"></i></Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Card>
            </Col>
        )
    }

    getHistory() {
        HttpConfig().get("get-history-user/"+this.state.id)
            .then(res => {
                console.log(res);
                if (res.data.status) {
                    this.setState({history:res.data.history});
                }
                else {

                }
            }).catch(err => {
            console.log(err);
        })
    }
}


export default Profile;
