/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container, FormGroup, Modal,
    Pagination, PaginationItem, PaginationLink,
    Row,
    Table
} from "reactstrap";
import moment from "moment";
import Loader from "react-loader-spinner";
import {HttpConfig} from "../../tools/Http";
import Select2 from "react-select2-wrapper";
import TagsInput from "react-tagsinput";

export default class emails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emails:[{
                id:"1",
                subject:"Testing",
                body:"Testing and email",
                recipients:"5 recipients",
                created_at:"15 Jun 2019 20:26"
            }],
            loading: true,
            //pagination
            current_page: null,
            first_page_url: null,
            last_page_url: null,
            next_page_url: null,
            prev_page_url: null,
            last_page: null,
            total: null,
            per_page: null,
            tagsinput: [
                "ralamosm@mac.com",
                "frikazoyd@icloud.com",
                "citizenl@sbcglobal.net",
                "dmiller@verizon.net",
            ],
        };
    }

    componentDidMount() {
        // this.getemails('failed');
    }

    render() {
        return (
            <>
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    {this.mutableTable()}
                    {this.getmodal()}
                </Container>
            </>        )
    }

    getHeader(){
        return (
            <>
                <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
                    <Container fluid>
                        <div className="header-body">
                            <Row className="align-items-center py-4">
                                <Col lg="6" xs="7">
                                    <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                                        {this.props.name}
                                    </h6>{" "}
                                    <Breadcrumb
                                        className="d-none d-md-inline-block ml-lg-4"
                                        listClassName="breadcrumb-links breadcrumb-dark"
                                    >
                                        <BreadcrumbItem>
                                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                                <i className="fas fa-home" />
                                            </a>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem>
                                            <a href="#pablo" onClick={e => e.preventDefault()} >
                                                {"Sent Emails"}
                                            </a>
                                        </BreadcrumbItem>
                                        {/*<BreadcrumbItem aria-current="page" className="active">*/}
                                        {/*    {this.props.name}*/}
                                        {/*</BreadcrumbItem>*/}
                                    </Breadcrumb>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        );
    }

    mutableTable(){
        return(
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            {this.state.loading ?
                                <Loader
                                    type="Puff"
                                    color="#5E72E4"
                                    height={30}
                                    width={30}

                                />:
                                <h3 className="mb-0 text-success">Sent Emails</h3>
                            }
                        </Col>
                    </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                        <th>#</th>
                        <th>Subject</th>
                        <th>Date</th>
                        <th>Body</th>
                        <th>Recipients</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody className="list">
                    {this.state.emails.map(el => {
                        return (
                            this.getUserRow(el)
                        )
                    }) }

                    </tbody>
                </Table>
                {this.state.emails.length === 0 && <div style={{height:400}}/>}

                {this.state.first_page_url &&
                <CardFooter className="py-4">
                    <nav aria-label="...">
                        <Pagination
                            className="pagination justify-content-end mb-0"
                            listClassName="justify-content-end mb-0"
                        >
                            <PaginationItem >
                                <PaginationLink
                                    href="#"
                                    onClick={e => {this.getemails(this.state.first_page_url);e.preventDefault()}}
                                    tabIndex="-1"
                                >
                                    <i className="fas fa-angle-double-left" />
                                    <span className="sr-only">Previous</span>
                                </PaginationLink>
                            </PaginationItem>
                            {this.state.prev_page_url &&
                            <PaginationItem>
                                <PaginationLink
                                    href="#"
                                    onClick={e => {this.getemails(this.state.prev_page_url);e.preventDefault()}}
                                >
                                    {this.state.current_page-1}
                                </PaginationLink>
                            </PaginationItem>
                            }
                            <PaginationItem className="active">
                                <PaginationLink
                                    href="#"
                                    onClick={e => e.preventDefault()}
                                >
                                    {this.state.current_page}
                                </PaginationLink>
                            </PaginationItem>
                            {this.state.next_page_url &&
                            <PaginationItem>
                                <PaginationLink
                                    href="#"
                                    onClick={e => {this.getemails(this.state.next_page_url);e.preventDefault()}}
                                >
                                    {this.state.current_page+1} <span className="sr-only">(current)</span>
                                </PaginationLink>
                            </PaginationItem>
                            }
                            <PaginationItem>
                                <PaginationLink
                                    href="#"
                                    onClick={e => {this.getemails(this.state.last_page_url);e.preventDefault()}}
                                >
                                    <i className="fas fa-angle-double-right" />
                                    <span className="sr-only">Next</span>
                                </PaginationLink>
                            </PaginationItem>
                        </Pagination>
                    </nav>
                </CardFooter>
                }
            </Card>
        );
    }



    getemails(param){
        this.setState({emails:[],loading:true});
        HttpConfig().get('emails/'+param)
            .then(res => {
                if (res.data.status) {
                    this.setState({
                        emails:res.data.emails.data ? res.data.emails.data : [],
                        loading:false,
                        first_page_url: this.strip(res.data.emails.first_page_url),
                        last_page_url: this.strip(res.data.emails.last_page_url),
                        next_page_url: this.strip(res.data.emails.next_page_url),
                        prev_page_url: this.strip(res.data.emails.prev_page_url),
                        current_page: res.data.emails.current_page,
                        last_page: res.data.emails.last_page,
                        total: res.data.emails.total,
                        per_page: res.data.emails.per_page
                    },() => console.log(this.state));
                }
                else {
                    this.setState({loading:false})
                }
            }).catch(err => {
            //err
            this.setState({loading:false})
        })
    }

    getUserRow(el){
        return (
            <tr>
                <td>
                    <span className="text-muted">
                            {el.id}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                            {el.subject}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                            {/*{el.created_at ? moment(el.created_at).format('MMM DD YYYY hh:mm') : '---'}*/}
                        {el.created_at}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                            {el.body}
                    </span>
                </td>
                <td>
                    <span className="text-muted" style={{cursor:"pointer"}}>
                        <u onClick={() => this.setState({modal:true})}>
                            {el.recipients}
                        </u>
                    </span>
                </td>
                <td>
                    <Button
                        size={"sm"}
                        className={"btn-default"}
                        onClick={() =>this.setState({modal:false})}
                        >
                        Resend
                    </Button>
                </td>
            </tr>
        );
    }

    strip(link){
        if(!link)
            return null;
        link = link.split('/super/emails/');
        return link[link.length-1];
    }

    getmodal(){
        return (
            <Modal
                className="modal-dialog-centered"
                isOpen={this.state.modal}
                toggle={() => this.setState({modal:false})}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        View Recipients
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() =>this.setState({modal:false})}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col xl={12}>
                            <TagsInput
                                onlyUnique
                                className="bootstrap-tagsinput"
                                onChange={() => {}}
                                value={this.state.tagsinput}
                                tagProps={{ className: "tag badge mr-1" }}
                                inputProps={{
                                    className: "",
                                    placeholder: ""
                                }}
                            />
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <Button
                        className="ml-auto disabled"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => {this.setState({modal:false})} }
                    >
                        Close
                    </Button>
                </div>
            </Modal>)
    }
}
