/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    InputGroup,
    Modal,
    Row,
    Table,
} from "reactstrap";
import moment from "moment";
import Loader from "react-loader-spinner";
import {HttpConfig} from "../../tools/Http";
import classnames from "classnames";
export default class TokenExpired extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            crawlers: {},
            err:"",
            succ:"",
        };
    }

    componentDidMount() {
        this.getToken()
    }

    async getToken() {
        HttpConfig().post("/get-token-cookie", {
           key_mobi: window.key_mobi
        }).then(res => {
            this.setState({cookies: res.data})
        });
        const response = await fetch('/2baf3dfa-4ab2-441e-ae0e-745147d7b5d7/crawler/appstorespy.json');
        const data = await response.json();
        this.setState({crawlers: data, loading: false})
    }

    async saveToken() {
        HttpConfig().post("/set-new-token-cookie", {
            key_mobi: window.key_mobi,
            token_cookies: this.state.cookies
        }).then(res => {
            if (res.data.status) {
                alert(res.data.message);
                this.setState({notificationModal:false});
            }
        })
    }
    render() {
        return (
            <>
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    {this.mutableTable()}
                    {this.getModal()}
                </Container>
            </>
        )
    }
    getHeader(){
        return (
            <>
                <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
                    <Container fluid>
                        <div className="header-body">
                            <Row className="align-items-center py-4">
                                <Col lg="6" xs="7">
                                    <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                                        {this.props.name}
                                    </h6>
                                    <Breadcrumb
                                        className="d-none d-md-inline-block ml-lg-4"
                                        listClassName="breadcrumb-links breadcrumb-dark"
                                    >
                                        <BreadcrumbItem>
                                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                                <i className="fas fa-home" />
                                            </a>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem>
                                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                                {"Status Token"}
                                            </a>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                </Col>
                                <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                                    <Button className="btn-neutral" color="default" size="sm" onClick={() =>
                                        this.setState({notificationModal:true})}>
                                        Upgrade Cookies AppStoreSpy
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        );
    }


    mutableTable(){
        return(
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            {this.state.loading ?
                                <Loader
                                    type="Puff"
                                    color="#5E72E4"
                                    height={30}
                                    width={30}

                                />:
                                <h3 className="mb-0">Token or Cookies Crawlers</h3>
                            }
                        </Col>
                    </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                        <th>Status</th>
                        <th>Status Code</th>
                        <th>Message</th>
                        <th>Crawler</th>
                        <th>Date</th>
                    </tr>
                    </thead>
                    <tbody className="list">
                        {this.state.crawlers.map ? this.state.crawlers.map(el => {
                            return (
                                this.getStatusCrawler(el)
                            )
                        }) : Object.keys(this.state.crawlers).map(el => {
                            return (
                                this.getStatusCrawler(this.state.crawlers[el])
                            )
                        }) }
                    </tbody>
                </Table>
                {this.state.crawlers.length === 0 && <div style={{height:400}}/>}
            </Card>
        );
    }


    getStatusCrawler(el){
        return (
            <tr>
                <td>
                    <Badge color={""} className="badge-dot mr-4">
                        <i className={"bg-"+(el.status ? "success" : 'warning')} />
                        <span className="status">{(el.status ? "True" : 'False')}</span>
                    </Badge>
                </td>
                <td>
                    <span className="text-muted">
                        {el.status_code}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                        {el.message}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                        {el.site}
                    </span>
                </td>
                <td className="table-actions">
                    <span className="text-muted" title={el.date}>{moment(el.date).fromNow()}</span>
                </td>
            </tr>
        );
    }

    getModal(){
        return (
            <Modal
                className="modal-dialog-centered"
                isOpen={this.state.notificationModal}
                toggle={() => this.setState({notificationModal:false})}>
                <div className={"modal-content bg-gradient-primary"} style={{}}>
                    <Card className="bg-transparent">
                        <CardBody>
                            <div className="mt-4">
                                <Form className="form-primary" role="form">
                                    <FormGroup>
                                        <InputGroup
                                            className={classnames(
                                                "input-group-alternative mb-3",
                                                {
                                                    focused: this.state.token_code
                                                }
                                            )}>
                                            <textarea className='form-control' rows="13" value={this.state.cookies} onChange={e => this.setState({cookies: e.target.value})}>
                                            </textarea>
                                        </InputGroup>
                                    </FormGroup>
                                    <Button block color="info" type="button" onClick={() => this.saveToken()}>
                                        Save new Token
                                    </Button>
                                </Form>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        );
    }
}