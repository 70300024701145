/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    UncontrolledTooltip,
    Table,
    Media,
    Badge,
    Progress,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    CardFooter, Pagination, PaginationItem, PaginationLink, Spinner, Modal, Input
} from "reactstrap";
import { Line, Bar, Doughnut, Pie } from "react-chartjs-2";

import {
    chartExample3,
    parseOptions,
    chartOptions,
} from "variables/charts.js";
import Chart from "chart.js";
import moment from "moment";
import {HttpConfig} from "../../tools/Http";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import {Link} from "react-router-dom";

export default class extends React.Component {
    constructor(props) {
        super(props);
        if (window.Chart) {
            parseOptions(Chart, chartOptions());
        }
        this.state = {
            usersGrowth : {labels: [], datasets: [{data: []}]},
            usersGrowthAccum : {labels: [], datasets: [{data: []}]},
            period:'One Month',
            tableTitle:{
                'active' : {title: 'Active Users', badge:'success', status:'Active'},
                'pending' :  {title: 'CIH Pending Users', badge:'info', status:'Pending'},
                'expired' :  {title: 'Expired Accounts', badge:'warning', status:'Expired'},
                'noplans' :  {title: 'No Plans Accounts', badge:'danger', status:'Not Subscribed'},
            },
            users: [],
            selected: 'active',
            loading: true,
            onlineUsers:[],
            //pagination
            current_page: null,
            first_page_url: null,
            last_page_url: null,
            next_page_url: null,
            prev_page_url: null,
            last_page: null,
            total: null,
            search: '',
            onlineSearch: '',
            per_page: null
        }
    }

    componentDidMount() {
        this.getUsers(this.state.selected);
        this.getOnlineUsers('realtime');
        this.getData(moment().subtract(30, "days").format("YYYY-MM-DD"),'One Month');
    }

    render() {
        return (
            <>
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    {this.getCharts()}
                    {this.mutableTable()}
                    {this.getRealTimeTable()}
                </Container>
            </>
        )
    }

    mutableTable(){
        return(
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col xs="4">
                            {this.state.loading ?
                                <Loader
                                    type="Puff"
                                    color="#5E72E4"
                                    height={30}
                                    width={30}
                                />:
                                <h3 className={"text-"+this.state.tableTitle[this.state.selected].badge+" mb-0"}>{this.state.tableTitle[this.state.selected].title}</h3>
                            }
                        </Col>
                        <Col xs="4">
                            <Input
                                value={this.state.search}
                                placeholder={"Search User"}
                                onChange={e => this.setState({search: e.target.value})}
                            />
                        </Col>
                        <Col className="mt-3 mt-md-0 text-md-right" xs="4">
                            <Button className="" color={this.state.selected === 'active' ? 'success' : 'secondary' } size="sm" onClick={() =>
                            {this.setState({selected:'active'},() => this.getUsers(this.state.selected))}}>
                                Active
                            </Button>
                            {/*<Button className="" color={this.state.selected === 'pending' ? 'info' : 'secondary' } size="sm" onClick={() =>*/}
                            {/*{this.setState({selected:'pending'},() => this.getUsers(this.state.selected))}}>*/}
                            {/*    Pending*/}
                            {/*</Button>*/}
                            <Button className="" color={this.state.selected === 'expired' ? 'warning' : 'secondary' } size="sm" onClick={() =>
                            {this.setState({selected:'expired'},() => this.getUsers(this.state.selected))}}>
                                Expired
                            </Button>
                            <Button className="" color={this.state.selected === 'noplans' ? 'danger' : 'secondary' } size="sm" onClick={() =>
                            {this.setState({selected:'noplans'},() => this.getUsers(this.state.selected))}}>
                                Inactive
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                        <th>Action</th>
                        <th>User</th>
                        <th>Created At</th>
                        <th>Expired At</th>
                        <th>Status</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>N-Requests</th>
                        <th>Last Action</th>
                        <th scope="col" />
                    </tr>
                    </thead>
                    <tbody className="list">
                        {this.state.users.filter((item) => item.name.toLowerCase().includes(this.state.search.toLowerCase()) || item.email.toLowerCase().includes(this.state.search.toLowerCase())).map(el => {
                            return (
                                this.getUserRow(el)
                            )
                        })}
                    </tbody>
                </Table>
                {this.state.users.length === 0 && <div style={{height:400}}/>}

                {this.state.first_page_url &&
                <CardFooter className="py-4">
                    <nav aria-label="...">
                        <Pagination
                            className="pagination justify-content-end mb-0"
                            listClassName="justify-content-end mb-0"
                        >
                            <PaginationItem >
                                <PaginationLink
                                    href="#"
                                    onClick={e => {this.getUsers(this.state.first_page_url);e.preventDefault()}}
                                    tabIndex="-1"
                                >
                                    <i className="fas fa-angle-double-left" />
                                    <span className="sr-only">Previous</span>
                                </PaginationLink>
                            </PaginationItem>
                            {this.state.prev_page_url &&
                            <PaginationItem>
                                <PaginationLink
                                    href="#"
                                    onClick={e => {this.getUsers(this.state.prev_page_url);e.preventDefault()}}
                                >
                                    {this.state.current_page-1}
                                </PaginationLink>
                            </PaginationItem>
                            }
                            <PaginationItem className="active">
                                <PaginationLink
                                    href="#"
                                    onClick={e => e.preventDefault()}
                                >
                                    {this.state.current_page}
                                </PaginationLink>
                            </PaginationItem>
                            {this.state.next_page_url &&
                            <PaginationItem>
                                <PaginationLink
                                    href="#"
                                    onClick={e => {this.getUsers(this.state.next_page_url);e.preventDefault()}}
                                >
                                    {this.state.current_page+1} <span className="sr-only">(current)</span>
                                </PaginationLink>
                            </PaginationItem>
                            }
                            <PaginationItem>
                                <PaginationLink
                                    href="#"
                                    onClick={e => {this.getUsers(this.state.last_page_url);e.preventDefault()}}>
                                    <i className="fas fa-angle-double-right" />
                                    <span className="sr-only">Next</span>
                                </PaginationLink>
                            </PaginationItem>
                        </Pagination>
                    </nav>
                </CardFooter>
                }
            </Card>
        );
    }

    getHeader(){
        return (
            <>
                <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
                    <Container fluid>
                        <div className="header-body">
                            <Row className="align-items-center py-4">
                                <Col lg="6" xs="7">
                                    <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                                        {this.props.name}
                                    </h6>{" "}
                                    <Breadcrumb
                                        className="d-none d-md-inline-block ml-lg-4"
                                        listClassName="breadcrumb-links breadcrumb-dark"
                                    >
                                        <BreadcrumbItem>
                                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                                <i className="fas fa-home" />
                                            </a>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem>
                                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                                {"Users"}
                                            </a>
                                        </BreadcrumbItem>
                                        {/*<BreadcrumbItem aria-current="page" className="active">*/}
                                        {/*    {this.props.name}*/}
                                        {/*</BreadcrumbItem>*/}
                                    </Breadcrumb>
                                </Col>
                                <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                                    <Button className="btn-neutral" color="default" size="sm" onClick={() =>
                                        this.getData(moment().subtract(180, "days").format("YYYY-MM-DD"),'Six Months')
                                    }>
                                        6 Months
                                    </Button>
                                    <Button className="btn-neutral" color="default" size="sm" onClick={() =>
                                        this.getData(moment().subtract(90, "days").format("YYYY-MM-DD"),'Three Months')
                                    }>
                                        3 Months
                                    </Button>
                                    <Button className="btn-neutral" color="default" size="sm" onClick={() =>
                                        this.getData(moment().subtract(30, "days").format("YYYY-MM-DD"),'One Month')
                                    }>
                                        1 Month
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        );
    }

    getCharts(){
        return (
            <Row>
                <Col xl="6">
                    <Card>
                        <CardHeader>
                            <h6 className="surtitle">{this.state.period} Overview</h6>
                            <h5 className="h3 mb-0">Users Growth</h5>
                        </CardHeader>
                        <CardBody>
                            <div className="chart">
                                <Line
                                    data={this.state.usersGrowth}
                                    options={chartExample3.options}
                                    id="chart-sales"
                                    className="chart-canvas"
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl="6">
                    <Card>
                        <CardHeader>
                            <h6 className="surtitle">{this.state.period} Overview</h6>
                            <h5 className="h3 mb-0">Users Accumulated Growth</h5>
                        </CardHeader>
                        <CardBody>
                            <div className="chart">
                                <Line
                                    data={this.state.usersGrowthAccum}
                                    options={chartExample3.options}
                                    id="chart-sales"
                                    className="chart-canvas"
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    getRealTimeTable(){
        return (
            <Card>
                <CardHeader className="border-0">
                    <Row>
                        <Col xs="4">
                            <h3 className="mb-0 text-warning">Users Online Now</h3>
                        </Col>
                        <Col xs="4">
                            <Input
                                value={this.state.onlineSearch}
                                placeholder={"Search Online User"}
                                onChange={e => this.setState({onlineSearch: e.target.value})}
                            />
                        </Col>
                        <Col xs="4">
                            
                        </Col>
                    </Row>
                </CardHeader>

                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                        <th>User</th>
                        <th>Created At</th>
                        <th>Status</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>N-Requests</th>
                        <th>Last Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.onlineUsers.filter((item) => item.name.toLowerCase().includes(this.state.onlineSearch.toLowerCase()) || item.email.toLowerCase().includes(this.state.onlineSearch.toLowerCase())).map(el => {
                        return (
                            <tr>
                                <td className="table-user">
                                    <img
                                        alt="..."
                                        className="avatar rounded-circle mr-3"
                                        src={el.avatar}
                                    />
                                    <Link
                                        className="cursor-pointer"
                                        to={'/admin/user-info?id='+el.id}
                                    ><b>{el.name}</b></Link>
                                </td>
                                <td>
                                    <span className="text-muted">{moment(el.created_at).format('MMM DD YYYY')}</span>
                                </td>
                                <td>
                                    <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-success" />
                                        <span className="status">Online</span>
                                    </Badge>
                                </td>
                                <td>
                                    <span className="text-muted">{el.email}</span>
                                </td>
                                <td className="table-actions">
                                    <span className="text-muted">{el.phone}</span>
                                </td>
                                <td className="table-actions">
                                    <span className="text-muted">{el.num_requests}</span>
                                </td>
                                <td className="table-actions">
                                    <span className="text-muted">{moment(el.last_action).fromNow()}</span>
                                </td>
                            </tr>
                        )
                    })}

                    </tbody>
                </Table>
            </Card>
        )
    }

    getData(startDate,label){
        this.setState({usersGrowth : {labels: [], datasets: [{data: []}]},
            usersGrowthAccum : {labels: [], datasets: [{data: []}]},
            period:label});
        this.getUsersGrowth(startDate);
        this.getUsersGrowthAccum(startDate);
    }

    getUsersGrowth(startDate) {
        HttpConfig().get('users/growth/monthly/'+startDate)
            .then(res => {
                if (res.data.status) {
                    let labels = [];
                    let datasets = [];
                    res.data.entries.forEach((el) => {
                        labels.push(el.end);
                        datasets.push(el.count);
                    });
                    let usersGrowth = {
                        labels:labels,
                        datasets: [{data: datasets}]
                    };
                    this.setState({usersGrowth});
                }
                else {

                }
            }).catch(err => {
            //err
        })
    }
    getUsersGrowthAccum(startDate){
        HttpConfig().get('users/growth/accumulated/'+startDate)
            .then(res => {
                if (res.data.status) {
                    let labels = [];
                    let datasets = [];
                    res.data.entries.forEach((el) => {
                        labels.push(el.end);
                        datasets.push(el.count);
                    });
                    let usersGrowthAccum = {
                        labels:labels,
                        datasets: [{data: datasets}]
                    };
                    this.setState({usersGrowthAccum});
                }
                else {

                }
            }).catch(err => {
            //err
        })
    }

    getOnlineUsers(z){
        HttpConfig().get('users/'+z)
            .then(res => {
                if (res.data.status) {
                    this.setState({onlineUsers:res.data.users});
                }
                else {

                }
            }).catch(err => {
            //err
        })
    }

    getUsers(param){
        this.setState({users:[],loading:true});
        HttpConfig().get('users/'+param)
            .then(res => {
                console.log(res)
                if (res.data.status) {
                    this.setState({
                        users: res.data.users ? res.data.users : [],
                        loading:false,
                        first_page_url: this.strip(res.data.users.first_page_url),
                        last_page_url: this.strip(res.data.users.last_page_url),
                        next_page_url: this.strip(res.data.users.next_page_url),
                        prev_page_url: this.strip(res.data.users.prev_page_url),
                        current_page: res.data.users.current_page,
                        last_page: res.data.users.last_page,
                        total: res.data.users.total,
                        per_page: res.data.users.per_page
                    },() => console.log(this.state));
                }
                else {
                    this.setState({loading:false})
                }
            }).catch(err => {
            //err
            this.setState({loading:false})
        })
    }

    strip(link){
        if(!link)
            return null;
        link = link.split('/super/users/');
        return link[link.length-1];
    }

    getUserRow(el){
        /* let obj = el.register_ip && JSON.parse(el.register_ip);
        let location = obj ? obj.countryName+', '+obj.regionName : 'Unknown'; */
        return (
            <tr>
                <td className="table-actions">
                    {this.getActions(el.id)}
                </td>
                <td className="table-user">
                    <img
                        alt="..."
                        className="avatar rounded-circle mr-3"
                        src={el.avatar}
                    />
                    <Link
                        className="cursor-pointer"
                        to={'/admin/user-info?id='+el.id}
                    ><b>{el.name}</b></Link>
                </td>
                <td>
                    <span className="text-muted">
                        {el.created_at ? moment(el.created_at).format('MMM DD YYYY') : '---'}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                        {el.date_expired ? moment(el.date_expired).format('MMM DD YYYY') : '---'}
                    </span>
                </td>
                <td>
                    {<Badge color={""} className="badge-dot mr-4">
                        <i className={"bg-"+this.state.tableTitle[this.state.selected].badge} />
                        <span className="status">{this.state.tableTitle[this.state.selected].status}</span>
                    </Badge>}
                </td>
                <td>
                    <span className="text-muted" style={{textDecoration:'underline', cursor:'pointer'}} onClick={() => {
                        navigator.clipboard.writeText(el.email)
                    }}>{el.email}</span>
                </td>
                <td className="table-actions">
                    <span className="text-muted">{el.phone}</span>
                </td>
                <td className="table-actions">
                    <span className="text-muted">{el.num_requests}</span>
                </td>
                <td className="table-actions">
                    {el.last_action ? moment(el.last_action).fromNow() : '---'}
                </td>
            </tr>
        );
    }

    getActions(id){
        return(
            this.state.selected !== 'active' ?
                <UncontrolledDropdown>
                    <DropdownToggle
                        className="btn-icon-only text-success"
                        color=""
                        role="button"
                        size="lg"
                    >
                        <i  className="fas fa-plus-square" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                            href="#pablo"
                            onClick={e => {e.preventDefault(); this.setPlan(2,id)}}
                        >
                            1 Month
                        </DropdownItem>
                        <DropdownItem
                            href="#pablo"
                            onClick={e => {e.preventDefault(); this.setPlan(3,id)}}
                        >
                            3 Month
                        </DropdownItem>
                        <DropdownItem
                            href="#pablo"
                            onClick={e => {e.preventDefault(); this.setPlan(4,id)}}
                        >
                            6 Month
                        </DropdownItem>
                        <DropdownItem
                            href="#pablo"
                            onClick={e => {e.preventDefault(); this.setPlan(5,id)}}
                        >
                           1 Year
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown> :
                <UncontrolledDropdown>
                    <DropdownToggle
                        className="btn-icon-only text-danger"
                        color=""
                        role="button"
                        size="lg"
                    >
                        <i className="fas fa-ban success"/>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                            href="#pablo"
                            onClick={e => {e.preventDefault(); this.revokePlan(id)}}
                        >
                            Remove Plan
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>

        );
    }

    setPlan(plan, id){
        this.setState({loading:true})
        HttpConfig().post('users/setplan', {
            user_id: id,
            plan_id: plan
        }).then(res => {
            if (res.data.status) {
                this.getUsers(this.state.selected);
            }
            else {
                this.setState({loading:false})
            }
        }).catch(err => {
            this.setState({loading:false})
        })
    }


    revokePlan(id){
        this.setState({loading:true})
        HttpConfig().post('users/revokeplan', {
            user_id:id
        }).then(res => {
            if (res.data.status) {
                this.getUsers(this.state.selected);
            }
            else {
                this.setState({loading:false})
            }
        }).catch(err => {
            this.setState({loading:false})
        })
    }

}
