/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container, DropdownToggle, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal,
    Pagination, PaginationItem, PaginationLink,
    Row,
    Table
} from "reactstrap";
import moment from "moment";
import Loader from "react-loader-spinner";
import {HttpConfig} from "../../tools/Http";
import ReactDatetime from "react-datetime";
import ReactQuill from "react-quill";
import classnames from "classnames";
import Select2 from "react-select2-wrapper";
import TagsInput from "react-tagsinput";

export default class Emails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal:true,
            tagsinput: [
                "ralamosm@mac.com",
                "frikazoyd@icloud.com",
                "citizenl@sbcglobal.net",
                "dmiller@verizon.net",
            ],
            reactQuillText: ""
        };

    }

    handleTagsinput = tagsinput => {
        this.setState({ tagsinput });
    };
    handleReactQuillChange = value => {
        this.setState({
            reactQuillText: value
        });
    };
    componentDidMount() {
    }

    render() {
        return (
            <>
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    <Row>
                        <Col xl={2}/>
                        <Col xl={8}>
                            {this.renderBody()}
                        </Col>
                    </Row>
                    {this.getmodal()}
                </Container>
            </>        )
    }

    renderBody(){
        return(
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="6">
                            <h3 className="mb-0">Emails</h3>
                        </Col>
                        <Col xl={6} className={"text-right"}>
                            <Button
                                color="info"
                                href={"/admin/sent-emails"}
                                size="sm"
                            >
                                Sent Emails
                            </Button>
                            <Button
                                color="info"
                                onClick={e => this.setState({modal:true})}
                                size="sm"
                            >
                                Add Recipients
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <div className={"card-body"}>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="exampleDatepicker"
                                >
                                    Subject
                                </label>
                                <Input
                                    defaultValue="Epic Givaway"
                                    id="input-username"
                                    placeholder="Username"
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="exampleDatepicker"
                                >
                                    Body
                                </label>
                                <Form>
                                    <div
                                        data-quill-placeholder="Quill WYSIWYG"
                                        data-toggle="quill"
                                    />
                                    <ReactQuill
                                        value={this.state.reactQuillText}
                                        onChange={this.handleReactQuillChange}
                                        theme="snow"
                                        modules={{
                                            toolbar: [
                                                ["bold", "italic"],
                                                ["link", "blockquote", "code", "image"],
                                                [
                                                    {
                                                        list: "ordered"
                                                    },
                                                    {
                                                        list: "bullet"
                                                    }
                                                ]
                                            ]
                                        }}
                                    />
                                </Form>
                            </FormGroup>
                        </Col>
                        <Col xl={12} className={"text-right"}>
                            <Button
                                color="primary"
                                href="#pablo"
                                onClick={e => window.location.href='/admin/jobs'}
                                size="sm"
                            >
                                Send
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Card>
        );
    }
    getHeader(){
        return (
            <>
                <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
                    <Container fluid>
                        <div className="header-body">
                            <Row className="align-items-center py-4">
                                <Col lg="6" xs="7">
                                    <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                                        {this.props.name}
                                    </h6>{" "}
                                    <Breadcrumb
                                        className="d-none d-md-inline-block ml-lg-4"
                                        listClassName="breadcrumb-links breadcrumb-dark"
                                    >
                                        <BreadcrumbItem>
                                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                                <i className="fas fa-home" />
                                            </a>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem>
                                            <a href="#pablo" onClick={e => e.preventDefault()} >
                                                {"Emails And Notifications"}
                                            </a>
                                        </BreadcrumbItem>
                                        {/*<BreadcrumbItem aria-current="page" className="active">*/}
                                        {/*    {this.props.name}*/}
                                        {/*</BreadcrumbItem>*/}
                                    </Breadcrumb>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        );
    }

    getmodal(){
        return (
            <Modal
                className="modal-dialog-centered"
                isOpen={this.state.modal}
                toggle={() => this.setState({modal:false})}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        Add Recipients
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() =>this.setState({modal:false})}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="exampleDatepicker"
                                >
                                    Select Group
                                </label>
                                <Select2
                                    className="form-control"
                                    defaultValue="1"
                                    options={{
                                        placeholder: "Select"
                                    }}
                                    data={[
                                        { id: "1", text: "Active Users" },
                                        { id: "2", text: "Inactive Users" },
                                        { id: "3", text: "Pending Users" },
                                        { id: "4", text: "Expired Users" },
                                        { id: "5", text: "Pro Users" },
                                        { id: "6", text: "Unity Users" }
                                    ]}
                                />
                            </FormGroup>
                        </Col>
                        <Col xl={12}>
                            <TagsInput
                                onlyUnique
                                className="bootstrap-tagsinput"
                                onChange={this.handleTagsinput}
                                value={this.state.tagsinput}
                                tagProps={{ className: "tag badge mr-1" }}
                                inputProps={{
                                    className: "mt-2",
                                    placeholder: "Add Email"
                                }}
                            />
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <Button
                        className="ml-auto disabled"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => {this.setState({modal:false})} }
                    >
                        Close
                    </Button>
                </div>
            </Modal>)
    }

}
