/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import Chart from "chart.js";

import {
    chartExample3,
    parseOptions,
    chartOptions,
} from "variables/charts.js";
import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container, Pagination, PaginationItem, PaginationLink,
    Row,
    Table
} from "reactstrap";
import moment from "moment";
import {Line} from "react-chartjs-2";
import {HttpConfig} from "../../tools/Http";
import Loader from "react-loader-spinner";
import {Link} from "react-router-dom";

export default class Payments extends React.Component {
    constructor(props) {
        super(props);
        if (window.Chart) {
            parseOptions(Chart, chartOptions());
        }
        this.state = {
            paymentsGrowth : {labels: [], datasets: [{data: []}]},
            paymentsGrowthAccum : {labels: [], datasets: [{data: []}]},
            payments:[],
            usersUpgrade:[],
            loading: true,
        }
    }
    componentDidMount() {
        this.getData(moment().subtract(30, "days").format("YYYY-MM-DD"),'One Month');
        this.getPayments();
        this.getUsersUpgradeManual();
    }

    render() {
        return (
            <>
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    {this.getCharts()}
                    {this.mutableTable()}
                    {this.renderUsersUpgradeTable()}
                </Container>
            </>
        )
    }


    mutableTable(){
        return(
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            {this.state.loading ?
                                <Loader
                                    type="Puff"
                                    color="#5E72E4"
                                    height={30}
                                    width={30}

                                />:
                                <h3 className="mb-0">YouCanPay Payments History</h3>
                            }
                        </Col>
                    </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                        <th>#</th>
                        <th>User</th>
                        <th>Amount</th>
                        <th>Intervals</th>
                        <th>Issued At</th>
                        <th>Coupon Usage</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th scope="col" />
                    </tr>
                    </thead>
                    <tbody className="list">
                    {this.state.payments.map ? this.state.payments.map(el => {
                        return (
                            this.getUserRow(el)
                        )
                    }) : Object.keys(this.state.payments).map(el => {
                        return (
                            this.getUserRow(this.state.payments[el])
                        )
                    }) }

                    </tbody>
                </Table>
                {this.state.payments.length === 0 && <div style={{height:400}}/>}
            </Card>
        );
    }
    getHeader(){
        return (
            <>
                <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
                    <Container fluid>
                        <div className="header-body">
                            <Row className="align-items-center py-4">
                                <Col lg="6" xs="7">
                                    <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                                        {this.props.name}
                                    </h6>{" "}
                                    <Breadcrumb
                                        className="d-none d-md-inline-block ml-lg-4"
                                        listClassName="breadcrumb-links breadcrumb-dark"
                                    >
                                        <BreadcrumbItem>
                                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                                <i className="fas fa-home" />
                                            </a>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem>
                                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                                {"Payments"}
                                            </a>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                </Col>
                                <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                                    <Button className="btn-neutral" color="default" size="sm" onClick={() =>
                                        this.getData(moment().subtract(180, "days").format("YYYY-MM-DD"),'Six Months')
                                    }>
                                        6 Months
                                    </Button>
                                    <Button className="btn-neutral" color="default" size="sm" onClick={() =>
                                        this.getData(moment().subtract(90, "days").format("YYYY-MM-DD"),'Three Months')
                                    }>
                                        3 Months
                                    </Button>
                                    <Button className="btn-neutral" color="default" size="sm" onClick={() =>
                                        this.getData(moment().subtract(30, "days").format("YYYY-MM-DD"),'One Month')
                                    }>
                                        1 Month
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        );
    }

    getCharts(){
        return (
            <Row>
                <Col xl="6">
                    <Card>
                        <CardHeader>
                            <h6 className="surtitle">{this.state.period} Overview</h6>
                            <h5 className="h3 mb-0">Payments Growth</h5>
                        </CardHeader>
                        <CardBody>
                            <div className="chart">
                                <Line
                                    data={this.state.paymentsGrowth}
                                    options={chartExample3.options}
                                    id="chart-sales"
                                    className="chart-canvas"
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl="6">
                    <Card>
                        <CardHeader>
                            <h6 className="surtitle">{this.state.period} Overview</h6>
                            <h5 className="h3 mb-0">Payments Accumulated Growth</h5>
                        </CardHeader>
                        <CardBody>
                            <div className="chart">
                                <Line
                                    data={this.state.paymentsGrowthAccum}
                                    options={chartExample3.options}
                                    id="chart-sales"
                                    className="chart-canvas"
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    getData(startDate,label){
        this.setState({paymentsGrowth : {labels: [], datasets: [{data: []}]},
            paymentsGrowthAccum : {labels: [], datasets: [{data: []}]},
            period:label});
        this.getPaymentsGrowth(startDate);
        this.getPaymentsGrowthAccum(startDate);
    }


    getPaymentsGrowth(startDate){
        HttpConfig().get('payments/growth/monthly/'+startDate)
            .then(res => {
                if (res.data.status) {
                    let labels = [];
                    let datasets = [];
                    res.data.entries.forEach((el) => {
                        labels.push(el.end);
                        datasets.push(el.count);
                    });
                    let paymentsGrowth = {
                        labels:labels,
                        datasets: [{data: datasets}]
                    };
                    this.setState({paymentsGrowth});
                }
                else {

                }
            }).catch(err => {})
    }
    getPaymentsGrowthAccum(startDate){
        HttpConfig().get('payments/growth/accumulated/'+startDate)
            .then(res => {
                if (res.data.status) {
                    let labels = [];
                    let datasets = [];
                    res.data.entries.forEach((el) => {
                        labels.push(el.end);
                        datasets.push(el.count);
                    });
                    let paymentsGrowthAccum = {
                        labels:labels,
                        datasets: [{data: datasets}]
                    };
                    this.setState({paymentsGrowthAccum});
                }
                else {

                }
            }).catch(err => {})
    }
    getPayments(){
        this.setState({payments:[],loading:true});
        HttpConfig().get('payments/successful')
            .then(res => {
                if (res.data.status) {
                    this.setState({
                        payments:res.data.payments ? res.data.payments : [],
                        loading:false
                    });
                }
                else {
                    this.setState({loading:false})
                }
            }).catch(err => {this.setState({loading:false})})
    }

    getUserRow(el){
        return (
            <tr>
                <td>{el.id}</td>
                <td className="table-user">
                    <img alt="..." className="avatar rounded-circle mr-3"
                        src={el.avatar}/>
                    <Link className="cursor-pointer"
                        to={'/admin/user-info?id='+el.user_id}>
                        <b>{el.name}</b>
                    </Link>
                </td>
                <td>
                    <b className={"text-green"}>{el.amount} MAD</b>
                </td>
                <td>
                    <b className={"text-info"}>{el.interval_plan}</b>
                </td>
                <td>
                    <span className="text-muted">
                        {el.created_at ? moment(el.created_at).format('MMM DD YYYY hh:mm') : '---'}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                        {el.coupon_usage ? <b className={"text-warning"}>{el.coupon_usage}</b> : '---'}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                        <a href={'mailto:'+el.email}>{el.email}</a>
                    </span>
                </td>
                <td className="table-actions">
                    <span className="text-muted">
                        <a href={'tel:'+el.phone}>{el.phone}</a>
                    </span>
                </td>
            </tr>
        );
    }

    renderUsersUpgradeTable(){
        return (
            <Card>
                <CardHeader className="border-0">
                    <Row>
                        <Col xs="6">
                            <h3 className="mb-0 text-warning">Users Upgrade Manual</h3>
                        </Col>
                    </Row>
                </CardHeader>

                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                        <th>#</th>
                        <th>User</th>
                        <th>Amount</th>
                        <th>Intervals</th>
                        <th>Created At</th>
                        <th>Email</th>
                        <th>Phone</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.usersUpgrade.map((el, index) => {
                        return (
                            <tr>
                                <td>{index + 1}</td>
                                <td className="table-user">
                                    <img
                                        alt="..."
                                        className="avatar rounded-circle mr-3"
                                        src={el.avatar}
                                    />
                                    <Link
                                        className="cursor-pointer"
                                        to={'/admin/user-info?id='+el.id}
                                    ><b>{el.name}</b></Link>
                                </td>
                                <td>
                                    <b className={"text-green"}>{el.amount} MAD</b>
                                </td>
                                <td>
                                    <b className={"text-info"}>{el.interval_plan}</b>
                                </td>
                                <td>
                                    <span className="text-muted">
                                        {el.created_at ? moment(el.created_at).format('MMM DD YYYY hh:mm') : '---'}
                                    </span>
                                </td>
                                <td>
                                    <span className="text-muted">
                                        <a href={'mailto:'+el.email}>{el.email}</a>
                                    </span>
                                </td>
                                <td className="table-actions">
                                    <span className="text-muted">
                                        <a href={'tel:'+el.phone}>{el.phone}</a>
                                    </span>
                                </td>
                            </tr>
                        )
                    })}

                    </tbody>
                </Table>
            </Card>
        )
    }

    getUsersUpgradeManual(){
        this.setState({usersUpgrade:[],loading:true});
        HttpConfig().get('users-upgrade-manual')
            .then(res => {
                if (res.data.status) {
                    this.setState({
                        usersUpgrade:res.data.users ? res.data.users : [],
                        loading:false
                    });
                }
                else {
                    this.setState({loading:false})
                }
            }).catch(err => {this.setState({loading:false})})
    }
}
