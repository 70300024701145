/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import {JWTLogin} from "../../tools/JwtToken";

class Login extends React.Component {
    state = {
        password:'',
        email:'',
        error: {has_error: false, message: ''},
        isLoading: false
    };
    render() {
        return (
            <>
                <AuthHeader
                    title="Welcome!"
                    lead="Login to MobiOption Back-Office."
                />
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        <Col lg="5" md="7">
                            <Card className="bg-secondary border-0 mb-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <div className="text-center text-muted mb-4">
                                        <small>Sign in with your MobiOptions credentials</small>
                                        {this.validationMessage()}
                                    </div>

                                    <Form role="form">
                                        <FormGroup
                                            className={classnames("mb-3", {
                                                focused: this.state.focusedEmail
                                            })}
                                        >
                                            <InputGroup className="input-group-merge input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-email-83" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Email"
                                                    type="email"
                                                    onFocus={() => this.setState({ focusedEmail: true })}
                                                    onBlur={() => this.setState({ focusedEmail: false })}
                                                    onChange={(e) => this.setState({ email: e.target.value })}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup
                                            className={classnames({
                                                focused: this.state.focusedPassword
                                            })}
                                        >
                                            <InputGroup className="input-group-merge input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-lock-circle-open" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Password"
                                                    type="password"
                                                    onFocus={() =>
                                                        this.setState({ focusedPassword: true })
                                                    }
                                                    onBlur={() =>
                                                        this.setState({ focusedPassword: false })
                                                    }
                                                    onChange={(e) => this.setState({ password: e.target.value })}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <div className="custom-control custom-control-alternative custom-checkbox">
                                            <input
                                                className="custom-control-input"
                                                id=" customCheckLogin"
                                                type="checkbox"
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor=" customCheckLogin"
                                            >
                                                <span className="text-muted">Remember me</span>
                                            </label>
                                        </div>
                                        <div className="text-center">
                                            <Button className="my-4" color="info" type="button" disabled={this.state.isLoading} onClick={() => this.handleSubmit()}>
                                                Sign in
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

    handleSubmit() {
        if(!this.validateEmail())
            return;
        if(!this.validatePassword())
            return;

        this.setState({isLoading:true});
        let data = {
            email: this.state.email,
            password: this.state.password
        }

        JWTLogin(data, false, this.formValidation.bind(this));
    }

    formValidation(message) {
        this.setState({error: {has_error: true, message: message}, isLoading:false})
    }

    validationMessage() {
        if (this.state.error.has_error) {
            return (
                <div className='row'>
                    <div className="col-md-12 text-red">
                        {this.state.error.message}
                    </div>
                </div>
            )
        }
        return '';
    }

    validateEmail() {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!re.test(String(this.state.email).toLowerCase())){
            let error = {message:"Please insert a valid email", has_error:true};
            this.setState({error});
            console.log("invalidae email"+this.state.email);
            return false;
        }
        let error = {message:"", has_error:false};
        this.setState({error});
        return true;
    }

    validatePassword() {
        if(this.state.password.length <= 5){
            let error = {message:"Please insert a valid password", has_error:true};
            this.setState({error});
            return false;
        }
        let error = {message:"", has_error:false};
        this.setState({error});
        return true;
    }
}

export default Login;
