/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import AdminLayout from "./layouts/Admin";
import RTLLayout from "./layouts/RTL";
import AuthLayout from "./layouts/Auth";
import {JWTIs_connected} from "./tools/JwtToken";
//import IndexView from "./views";

export default class App extends React.Component {
    render() {
        window.api_url = "https://api.mobioptions.com/api/admin/";
        //window.api_url = "http://apiwi-mobioptions.test/api/admin/";
        window.key_mobi = "eyJpdiI6IkZSM1ZYT3dzUmVzNnBEYTEwRXQ2ZGc9PSIsInZhbHVlIjoiQzdMU1pKVSthV1JiNU8zQzVseG5tUkkwS1pxdWJydjRSb1Fha0thVjBybz0iLCJtYWMiOiJlODk0MDdlNDIyMWQ5MzVmZGY4NGU0OWY1YzI0Mzc0ZmE2N2Q4NGRkYjY2YzA5YTc0MzE0MzE2NjRjMjYzNTdhIiwidGFnIjoiIn0";
        /* For Live */
        window.api_course = "https://course.webschool.ma/api/admin/";
        window.key_access_course = "eyJpdiI6IkxybjBNRGR3c0ljOUZBL1dDS1FZZWc9PSIsInZhbHVlIjoiUkgvdmNEUjdSODVvNnZsc3VaTFFzakFNVlFiUDlQOGlYL0F1bXJNZjlKWkpTYThUQTdHRmlpcCt0SzhPcFlZNSIsIm1hYyI6ImVkMWZiYmQzZmE1YzJiNjQwNWY3MzhkMjA3NzJkNjI3YjczMTIzYTAzNjgzNWUzNzAwMzdiNGRlMDg5OTFmYWYiLCJ0YWciOiIifQ";
        /* For Test */
        /*
            window.api_course = "http://src.test/public/api/admin/";
            window.key_access_course = "eyJpdiI6Inh3NFZEQWNvSVA1cmxRSU1RZU5kNVE9PSIsInZhbHVlIjoic1dPYVQxV3pKVFVXdDgyM3RjSG5wY0pwVTVwWCtxNytUOW5VQVRzTjh5UU5hRUVpb2RvSGc3bVhWM01Od2Y5cSIsIm1hYyI6IjAyNTM1MDU0YmFlMThkYWRiM2E5MmQwYmM0ZDk4YjIxODQ5ZTVkYjBkN2Y4NDY5OTYzN2JkZjE5OWNlYTdhYWYiLCJ0YWciOiIifQ";
        */
        return this.getAppropriateRoutes();
    }

    getAppropriateRoutes() {
        if(JWTIs_connected()){
            return (
                <BrowserRouter>
                    <Switch>
                        <Route path="/" render={props => <AdminLayout {...props} />} />
                        <Redirect from="*" to="/" />
                    </Switch>
                </BrowserRouter>
            );
        }
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/auth" render={props => <AuthLayout {...props} />} />
                    <Redirect from="*" to="/auth" />
                </Switch>
            </BrowserRouter>
        );
    }
}
