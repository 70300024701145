/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardHeader,
    Col,
    Container, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal,
    Row,
    Table
} from "reactstrap";
import moment from "moment";
import Loader from "react-loader-spinner";
import {HttpConfig} from "../../tools/Http";
import classnames from "classnames";

export default class Coupons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            coupons:[],
            loading: true,
            showAddCoupon:false,
            code_coupon: null,
            description: null,
            discount: null,
            expiration_date: null,
            tableTitle:{
                1: {badge:'success', status:'Active'},
                0: {badge:'danger', status:'Inactive'},
            },
        };


    }
    componentDidMount() {
        this.getCoupons();
    }

    render() {
        return (
            <>
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    {this.mutableTable()}
                </Container>
            </>        
        )
    }

    getHeader(){
        return (
            <>
                <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
                    <Container fluid>
                        <div className="header-body">
                            <Row className="align-items-center py-4">
                                <Col lg="6" xs="7">
                                    <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                                        {this.props.name}
                                    </h6>{" "}
                                    <Breadcrumb
                                        className="d-none d-md-inline-block ml-lg-4"
                                        listClassName="breadcrumb-links breadcrumb-dark"
                                    >
                                        <BreadcrumbItem>
                                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                                <i className="fas fa-home" />
                                            </a>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem>
                                            <a href="#pablo" onClick={e => e.preventDefault()} >
                                                {"Coupons"}
                                            </a>
                                        </BreadcrumbItem>
                                        {/*<BreadcrumbItem aria-current="page" className="active">*/}
                                        {/*    {this.props.name}*/}
                                        {/*</BreadcrumbItem>*/}
                                    </Breadcrumb>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        );
    }

    mutableTable(){
        return(
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            {this.state.loading ?
                                <Loader
                                    type="Puff"
                                    color="#5E72E4"
                                    height={30}
                                    width={30}
                                />:
                                <h3 className="mb-0 text-red">List Coupons</h3>
                            }
                        </Col>
                        <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                            <Button className="" color={'info'} size="sm" onClick={() =>
                            {this.setState({showAddCoupon:true})}}>
                                Add new Coupon
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                        <th>#</th>
                        <th>Code Promo</th>
                        <th>Discount</th>
                        <th>Status</th>
                        <th>Expiration date</th>
                        <th>Date created</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody className="list">
                        {this.state.coupons.map ? this.state.coupons.map(el => {
                            return (
                                this.getUserRow(el)
                            )
                        }) : Object.keys(this.state.coupons).map(el => {
                            return (
                                this.getUserRow(this.state.coupons[el])
                            )
                        }) }
                    </tbody>
                </Table>
                {this.state.coupons.length === 0 && <div style={{height:400}}/>}
                {this.renderAddApp()}
            </Card>
            
        );
    }



    getCoupons(){
        this.setState({coupons:[],loading:true});
        HttpConfig().get("list-coupons")
            .then(res => {
                if (res.data.status) {
                    this.setState({
                        coupons:res.data.coupons ? res.data.coupons : [],
                        loading:false,
                    },() => console.log(this.state));
                }
                else {
                    this.setState({loading:false})
                }
            }).catch(err => {
            this.setState({loading:false})
        })
    }

    deleteCoupon(id) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Are you sure?")) {
            HttpConfig().delete('remove-coupon/' + id)
            .then(response => {
                if(response.data.status) {
                    this.getCoupons();
                }
            }).catch(err => {console.log(err)})
        }
    }

    getUserRow(el){
        return (
            <tr>
                <td>
                    <span className="text-muted">
                            {el.id}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                            {el.code_promo}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                            {el.discount}
                    </span>
                </td>
                <td>
                    <td>
                        {<Badge color={""} className="badge-dot mr-4">
                            <i className={"bg-"+this.state.tableTitle[el.status].badge} />
                            <span className="status">{this.state.tableTitle[el.status].status}</span>
                        </Badge>}
                    </td>
                </td>
                <td>
                    <span className="text-muted">
                            {el.expiration_date ? moment(el.expiration_date).format('MMM DD YYYY hh:mm') : '---'}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                            {el.created_at ? moment(el.created_at).format('MMM DD YYYY hh:mm') : '---'}
                    </span>
                </td>
                <td>
                    <div>
                        <Button className={'btn btn-sm btn-outline-success'} title={'Copy Coupon'} onClick={e => {navigator.clipboard.writeText(el.code_promo);}}><i className="far fa-clipboard fa-lg"></i></Button>
                        <Button className={'btn btn-sm btn-outline-danger'} title={'Delete Page'} onClick={e => {this.deleteCoupon(el.id);e.preventDefault();}}><i className="fas fa-trash-alt fa-lg"></i></Button>
                    </div>
                </td>
            </tr>
        );
    }

    AddNewCoupon()
    {       
        HttpConfig().post('add-new-coupon', {
            code_coupon:this.state.code_coupon,
            description:this.state.description,
            discount:this.state.discount,
            expiration_date:this.state.expiration_date,
        })
        .then(res => {
            if (res.data.status) {
                this.setState({showAddCoupon:false});
                this.getCoupons();
            } else {
                this.setState({err: res.data.message});
            }
        })
        .catch(err => {})
    }

    renderAddApp(){
        return (
            <Modal
                className="modal-dialog-centered"
                isOpen={this.state.showAddCoupon}
                toggle={() => this.setState({showAddCoupon:false})}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        Add New Coupon
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() =>this.setState({showAddCoupon:false})}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col md="10">
                            <FormGroup>
                            <InputGroup className={classnames("input-group-merge", {
                                        focused: this.state.location
                                    })}>
                                <Input
                                    placeholder="Code Promo *"
                                    type="text"
                                    value={this.state.code_coupon}
                                    onFocus={e => this.setState({code_coupon: ""})}
                                    onChange={e => {this.setState({code_coupon:e.target.value, err: ""})}}
                                />
                             </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    placeholder="Description (Optional)"
                                    type="text"
                                    value={this.state.description}
                                    onFocus={e => this.setState({description: ""})}
                                    onChange={e => {this.setState({description:e.target.value, err: ""})}}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    placeholder="Discount *"
                                    type="number"
                                    maxLength="5"
                                    value={this.state.discount}
                                    onFocus={e => this.setState({discount: ""})}
                                    onChange={e => {this.setState({discount:e.target.value, err: ""})}}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>EXpiration Date (Optional)</label>
                                <Input
                                    placeholder="Expiration Date"
                                    type="date"
                                    value={this.state.expiration_date}
                                    onFocus={e => this.setState({expiration_date: ""})}
                                    onChange={e => {this.setState({expiration_date:e.target.value, err: ""})}}
                                />
                            </FormGroup>
                            {this.state.err !== '' && <p className={"text-danger"}>{this.state.err}</p>}
                            {this.state.succ !== '' && <p className={"text-success"}>{this.state.succ}</p>}
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <Button
                        color="success"
                        role="button"
                        onClick={e => {this.AddNewCoupon();e.preventDefault();}}
                    >
                        Add Coupon
                    </Button>
                    <Button className="ml-auto"
                        color="link"
                        data-dismiss="modal" type="button"
                        onClick={() => {this.setState({showAddCoupon:false})} }>
                        Close
                    </Button>
                </div>
            </Modal>)
    }
}
