/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import Chart from "chart.js";
import {
    Badge,
    Button,
    Card,
    CardBody, CardFooter,
    CardHeader,
    Col,
    Container,
    ListGroupItem, Media,
    Nav,
    NavItem,
    NavLink, Pagination, PaginationItem, PaginationLink, Progress,
    Row, Table, UncontrolledDropdown, UncontrolledTooltip
} from "reactstrap";
import classnames from "classnames";
import {Bar, Line} from "react-chartjs-2";
import {Link} from "react-router-dom";

import {
    chartExample1,
    chartExample2,
    parseOptions,
    chartOptions,
} from "variables/charts.js";
import CardsHeader from "../../components/Headers/CardsHeader";
import {VectorMap} from "react-jvectormap";
import {HttpConfig} from "../../tools/Http";
import moment from "moment";
import Loader from "react-loader-spinner";

let mapData = {
    AU: 760,
    BR: 550,
    CA: 120,
    DE: 1300,
    FR: 540,
    GB: 690,
    GE: 200,
    IN: 200,
    RO: 600,
    RU: 300,
    MA: 2920
};
class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeNav: 1,
            chartExample1Data: "data1",
            jobs: [],
            onlineUsers: [],
            paymentsGrowth : {labels: [], datasets: [{data: []}]},
            usersGrowth : {labels: [], datasets: [{data: []}]},

        };
        if (window.Chart) {
            parseOptions(Chart, chartOptions());
        }
    }

    componentDidMount() {
        this.getData(moment().subtract(30, "days").format("YYYY-MM-DD"))
    }

    getData(startDate){
        this.setState({
            paymentsGrowth : {labels: [], datasets: [{data: []}]},
            usersGrowth : {labels: [], datasets: [{data: []}]}
        });
        this.getPaymentsGrowth(startDate);
        this.getUsersGrowth(startDate);
        this.getJobs();
        this.getOnlineUsers();
    }

    getOnlineUsers(){
        HttpConfig().get('users/realtime')
            .then(res => {
                if (res.data.status) {
                    this.setState({onlineUsers:res.data.users});
                }
                else {

                }
            }).catch(err => {
            //err
        })
    }

    getJobs(){
        HttpConfig().get('jobs/failed')
            .then(res => {
                if (res.data.status) {
                    this.setState({
                        jobs:res.data.jobs.data ? res.data.jobs.data : [],
                    },() => console.log(this.state));
                }
                else {
                    this.setState({loading:false})
                }
            }).catch(err => {
            //err
            this.setState({loading:false})
        })
    }

    getUsersGrowth(startDate){
        HttpConfig().get('users/growth/monthly/'+startDate)
            .then(res => {
                if (res.data.status) {
                    let labels = [];
                    let datasets = [];
                    res.data.entries.forEach((el) => {
                        labels.push(el.end);
                        datasets.push(el.count);
                    });
                    let usersGrowth = {
                        labels:labels,
                        datasets: [{data: datasets}]
                    };
                    this.setState({usersGrowth});
                }
                else {

                }
            }).catch(err => {
            //err
        })
    }
    getPaymentsGrowth(startDate){
        HttpConfig().get('payments/growth/monthly/'+startDate)
            .then(res => {
                if (res.data.status) {
                    let labels = [];
                    let datasets = [];
                    res.data.entries.forEach((el) => {
                        labels.push(el.end);
                        datasets.push(el.count);
                    });
                    let paymentsGrowth = {
                        labels:labels,
                        datasets: [{data: datasets}]
                    };
                    this.setState({paymentsGrowth});
                }
                else {

                }
            }).catch(err => {
            //err
        })
    }

    render() {
        return (
            <>
                <CardsHeader name="Default" parentName="Dashboards" />
                <Container className="mt--6" fluid>
                    <Row>
                        <Col xl="8">
                            <Card className="bg-default">
                                <CardHeader className="bg-transparent">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h6 className="text-light text-uppercase ls-1 mb-1">
                                                Overview
                                            </h6>
                                            <h5 className="h3 text-white mb-0">Monthly Revenue</h5>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart">
                                        <Line
                                            data={this.state.paymentsGrowth}
                                            options={chartExample1.options}
                                            id="chart-sales-dark"
                                            className="chart-canvas"
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="4">
                            <Card>
                                <CardHeader className="bg-transparent">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h6 className="text-uppercase text-muted ls-1 mb-1">
                                                Users
                                            </h6>
                                            <h5 className="h3 mb-0">Monthly New Arriving Users</h5>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart">
                                        <Bar
                                            data={this.state.usersGrowth}
                                            options={chartExample2.options}
                                            className="chart-canvas"
                                            id="chart-bars"
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.getRealTimeTable()}
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
    getUserRow(el){
        return (
            <tr>
                <td>
                    <span className="text-muted">
                            {el.id}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                            {el.name}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                            {el.created_at ? moment(el.created_at).format('MMM DD YYYY hh:mm') : '---'}
                    </span>
                </td>
                <td>
                    <Badge color={""} className="badge-dot mr-4">
                        <i className={"bg-danger"} />
                        <span className="status">Failed</span>
                    </Badge>
                </td>
            </tr>
        );
    }

    getRealTimeTable(){
        return (
            <Card>
                <CardHeader className="border-0">
                    <Row>
                        <Col xs="6">
                            <h3 className="mb-0 text-success">Users Online Now</h3>
                        </Col>
                    </Row>
                </CardHeader>

                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                        <th>User</th>
                        <th>Created At</th>
                        <th>Status</th>
                        <th>N-requests</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Last Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.onlineUsers.map(el => {
                        return (
                            <tr>
                                <td className="table-user">
                                    <img
                                        alt="..."
                                        className="avatar rounded-circle mr-3"
                                        src={el.avatar}
                                    />
                                    <Link
                                        className="cursor-pointer"
                                        to={'/admin/user-info?id='+el.id}
                                    ><b>{el.name}</b></Link>
                                </td>
                                <td>
                                    <span className="text-muted">{moment(el.created_at).format('MMM DD YYYY')}</span>
                                </td>
                                <td>
                                    <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-success" />
                                        <span className="status">Online</span>
                                    </Badge>
                                </td>
                                <td>
                                    <span className="text-muted">{el.num_requests}</span>
                                </td>
                                <td>
                                    <span className="text-muted">{el.email}</span>
                                </td>
                                <td className="table-actions">
                                    <span className="text-muted">{el.phone}</span>
                                </td>
                                <td className="table-actions">
                                    <span className="text-muted">{moment(el.last_action).fromNow()}</span>
                                </td>
                            </tr>
                        )
                    })}

                    </tbody>
                </Table>
            </Card>
        )
    }

}

export default Dashboard;
