/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Login from "./views/pages/Login";
import Users from "./views/pages/Users";
import Payments from "./views/pages/Payments";
import Jobs from "./views/pages/Jobs";
import Apps from "./views/pages/Apps";
import Dashboard from "./views/pages/Dashboard";
import TokenExpired from "./views/pages/TokenExpired";
import WebSchool from "./views/pages/WebSchool";
import Emails from "./views/pages/Emails";
import SentEmails from "./views/pages/SentEmails";
import Profile from "./views/pages/Profile";
import Coupons from "views/pages/Coupons";

const routes = [
    {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
        redirect: true
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "ni ni-shop text-default",
        component: Dashboard,
        layout: "/admin"
    },
    {
        path: "/users",
        name: "Users",
        icon: "ni ni-single-02 text-info",
        component: Users,
        layout: "/admin"
    },
    {
        path: "/apps",
        name: "Hidden Apps",
        icon: "ni ni-app text-orange",
        component: Apps,
        layout: "/admin"
    },
    {
        path: "/payments",
        name: "Payments",
        icon: "ni ni-money-coins text-green",
        component: Payments,
        layout: "/admin"
    },
    {
        path: "/coupons",
        name: "coupons",
        icon: "ni ni-tag text-green",
        component: Coupons,
        layout: "/admin"
    },{
        path: "/jobs",
        name: "Failed Jobs",
        icon: "ni ni-building text-grey",
        component: Jobs,
        layout: "/admin"
    },{
        path: "/status-token",
        name: "Status Token",
        icon: "fa fa-exclamation-triangle text-purple",
        component: TokenExpired,
        layout: "/admin"
    },{
        path: "/web-school",
        name: "Manger (WebSchool)",
        icon: "fa fa-school text-blue",
        component: WebSchool,
        layout: "/admin"
    },/* {
        path: "/emails",
        name: "Email & Notifications",
        icon: "fa fa-bell text-red",
        component: Emails,
        layout: "/admin"
    }, */{
        path: "/sent-emails",
        name: "Sent Emails",
        icon: "fa fa-bell text-red",
        component: SentEmails,
        layout: "/admin",
        redirect: true
    },{
        path: "/user-info",
        name: "User Info",
        icon: "fa fa-bell text-red",
        component: Profile,
        layout: "/admin",
        redirect: true
    }
];

export default routes;
