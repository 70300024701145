/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    FormGroup,CardBody,
    Card, Button,
    CardHeader,
    Col,
    Container,
    Modal,
    Row,
    Table
} from "reactstrap";
import moment from "moment";
import Loader from "react-loader-spinner";
import {HttpConfig} from "../../tools/Http";

export default class jobs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logs:[],
            message: null,
            loading: true,
            total: null
        };


    }

    componentDidMount() {
        this.getLogs();
    }

    render() {
        return (
            <>
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    {this.mutableTable()}
                    {this.getModal()}
                </Container>
            </>        
        )
    }

    getHeader(){
        return (
            <>
                <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
                    <Container fluid>
                        <div className="header-body">
                            <Row className="align-items-center py-4">
                                <Col lg="6" xs="7">
                                    <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                                        {this.props.name}
                                    </h6>{" "}
                                    <Breadcrumb className="d-none d-md-inline-block ml-lg-4"
                                        listClassName="breadcrumb-links breadcrumb-dark">
                                        <BreadcrumbItem>
                                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                                <i className="fas fa-home" />
                                            </a>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem>
                                            <a href="#pablo" onClick={e => e.preventDefault()} >
                                                {"Failed Logs"}
                                            </a>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        );
    }

    mutableTable(){
        return(
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            {this.state.loading ?
                                <Loader
                                    type="Puff"
                                    color="#5E72E4"
                                    height={30}
                                    width={30}
                                />:
                                <h3 className="mb-0 text-red">Failed Logs</h3>
                            }
                        </Col>
                    </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                        <th>Message</th>
                        <th>Date</th>
                        <th>Country - Category - collection</th>
                        <th>error_type</th>
                        <th>error_message</th>
                    </tr>
                    </thead>
                    <tbody className="list">
                    {this.state.logs.map ? this.state.logs.map(el => {
                        return (
                            this.getFailedLogs(el)
                        )
                    }) : Object.keys(this.state.logs).map(el => {
                        return (
                            this.getFailedLogs(this.state.logs[el])
                        )
                    }) }

                    </tbody>
                </Table>
                {this.state.logs.length === 0 && <div style={{height:400}}/>}
            </Card>
        );
    }



    async getLogs(){
        this.setState({logs:[],loading:true});
        const response = await fetch('/2baf3dfa-4ab2-441e-ae0e-745147d7b5d7/logs/appstorespy.json');
        const data = await response.json();
        console.log(data);
        this.setState({logs: data, loading: false})
    }

    getFailedLogs(el){
        return (
            <tr>
                <td>
                    <span className="text-muted">
                            {el.message}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                            {el.date ? moment(el.date).format('MMM DD YYYY hh:mm') : '---'}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                            {el.country} - {el.category} - {el.collection}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                            {el.error_type}
                    </span>
                </td>
                <td>
                            {/* {el.error_message} */}
                    <Button className="btn-neutral" color="default" size="sm" onClick={() =>
                        this.setState({notificationModal:true, message: el.error_message})}>
                        Message Error
                    </Button>
                </td>
            </tr>
        );
    }

    getModal() {
        return (
            <Modal className="modal-dialog-centered" isOpen={this.state.notificationModal}
                toggle={() => this.setState({notificationModal:false})}>
                <div className={"modal-content bg-gradient-primary"}>
                    <Card className="bg-transparent">
                        <CardBody>
                            <div className="mt-4">
                                <FormGroup>
                                    <textarea className='bg-dark form-control text-yellow' rows="13" value={this.state.message}></textarea>
                                </FormGroup>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        );
    }
}
