/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Modal,
    Row,
    Table,
} from "reactstrap";
import moment from "moment";
import Loader from "react-loader-spinner";
import Select2 from "react-select2-wrapper";
import TagsInput from "react-tagsinput";
import {HttpConfig, HttpCourseRequest} from "../../tools/Http";
import classnames from "classnames";
export default class WebSchool extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            courses: [],
            chapters: [],
            lessons:[],
            users: {},
            selected: 'courses',
            loading: true,
            crawlers: {},
            err:"",
            succ:"",
        };
    }

    componentDidMount() {
        this.getCourses();
        this.getChapters();
    }

    render() {
        return (
            <>
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    {this.mutableTable()}
                    {this.getModal()}
                </Container>
            </>
        )
    }
    getHeader(){
        return (
            <>
                <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
                    <Container fluid>
                        <div className="header-body">
                            <Row className="align-items-center py-4">
                                <Col lg="3" xs="2">
                                    <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                                        {this.props.name}
                                    </h6>
                                    <Breadcrumb className="d-none d-md-inline-block ml-lg-4"
                                        listClassName="breadcrumb-links breadcrumb-dark">
                                        <BreadcrumbItem>
                                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                                <i className="fas fa-home" />
                                            </a>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem>
                                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                                {"Manger (WebSchool)"}
                                            </a>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                </Col>
                                <Col className="mt-3 mt-md-0 text-md-right" lg="9" xs="10">
                                    <Button className="btn-neutral" color="default" size="sm" onClick={() =>
                                        this.setState({modalCourse:true,update_course: false,name_course: "", url_image: "",description_course: "",duration_course: ""})}>
                                        Add New Course
                                    </Button>
                                    <Button className="btn-neutral" color="default" size="sm" onClick={() =>
                                        this.setState({modalChapter:true,update_chapter: false,
                                            name_chapter: "",description_chapter: "",course_chapter: ""})}>
                                        Add New Chapter
                                    </Button>
                                    <Button className="btn-neutral" color="default" size="sm" onClick={() =>
                                        this.setState({modalLession:true,update_lesson: false,title_lession: "",
                                            description_lession: "",chapter_lession: "",
                                            url_lession: "",order_lession: ""})}>
                                        Add New Lession
                                    </Button>
                                    <Button className="btn btn-warning" size="sm" onClick={() =>
                                        this.setState({modalUser:true,update_user:false,name_user: "",
                                        email_user: "",password_user: ""})}>
                                        Add New User
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        );
    }
    mutableTable(){
        return(
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="6">
                            {this.state.loading ?
                                <Loader
                                    type="Puff"
                                    color="#5E72E4"
                                    height={30}
                                    width={30}

                                />:
                                    this.state.selected === "courses" ? (
                                        <>
                                            <h3 className="mb-0">List Courses</h3>
                                        </>
                                    ) : (
                                        <>
                                            <h3 className="mb-0">List Users</h3>
                                        </>
                                    )
                            }
                        </Col>
                        <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="6">
                            <Button className="" color={this.state.selected === 'courses' ? 'dark' : 'secondary' } size="sm" onClick={() =>
                            {this.setState({selected:'courses'},() => this.getCourses())}}>
                                Courses
                            </Button>
                            <Button className="" color={this.state.selected === 'chapters' ? 'warning' : 'secondary' } size="sm" onClick={() =>
                            {this.setState({selected:'chapters'},() => this.getChapters())}}>
                                Chapters
                            </Button>
                            <Button className="" color={this.state.selected === 'lessons' ? 'info' : 'secondary' } size="sm" onClick={() =>
                            {this.setState({selected:'lessons'},() => this.getlessons())}}>
                                Lessons
                            </Button>
                            <Button className="" color={this.state.selected === 'users' ? 'success' : 'secondary' } size="sm" onClick={() =>
                            {this.setState({selected:'users'},() => this.getUsers())}}>
                                Users
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                    {
                        this.state.selected === "courses" ? (
                            <>
                            <th>ID</th>
                            <th>Name Course</th>
                            <th>Duration</th>
                            <th>Created At</th>
                            <th>Actions</th>
                            </>
                        ) : this.state.selected === "chapters" ? (
                            <>
                                <th>ID</th>
                                <th>Name Course</th>
                                <th>Title</th>
                                <th>Created At</th>
                                <th>Actions</th>
                            </>
                        ) : this.state.selected === "lessons" ? (
                            <>
                                <th>ID</th>
                                <th>Name Chapter</th>
                                <th>Title</th>
                                <th>URL Video</th>
                                <th>Created At</th>
                                <th>Actions</th>
                            </>
                        ) : (
                            <>
                            <th>ID</th>
                            <th>FullName</th>
                            <th>E-mail</th>
                            <th>Password</th>
                            <th>Created At</th>
                            <th>Last Action</th>
                            <th>Actions</th>
                            </>
                        )
                    }

                        
                    </tr>
                    </thead>
                    <tbody className="list">
                    {
                        this.state.selected === "courses" ? (
                            this.state.courses.map ? this.state.courses.map((el, index) => {
                                return (
                                    this.getTableCourses(el, index)
                                )
                            }) : Object.keys(this.state.courses).map((el, index) => {
                                return (
                                    this.getTableCourses(this.state.courses[el], index)
                                )
                            })
                        ) : this.state.selected === "chapters" ? (
                            this.state.chapters.map ? this.state.chapters.map((el, index) => {
                                return (
                                    this.getTableChapters(el, index)
                                )
                            }) : Object.keys(this.state.chapters).map((el, index) => {
                                return (
                                    this.getTableChapters(this.state.chapters[el], index)
                                )
                            }) 
                        ) : this.state.selected === "lessons" ? (
                            this.state.lessons.map ? this.state.lessons.map((el, index) => {
                                return (
                                    this.getTableLessons(el,index)
                                )
                            }) : Object.keys(this.state.lessons).map((el, index) => {
                                return (
                                    this.getTableLessons(this.state.lessons[el],index)
                                )
                            }) 
                        ) : (
                            this.state.users.map ? this.state.users.map((el, index) => {
                                return (
                                    this.getTableUsers(el, index)
                                )
                            }) : Object.keys(this.state.users).map((el, index) => {
                                return (
                                    this.getTableUsers(this.state.users[el], index)
                                )
                            }) 
                        )
                    }
                    </tbody>
                </Table>
                {this.state.crawlers.length === 0 && <div style={{height:400}}/>}
            </Card>
        );
    }

    getCourses()
    {
        this.setState({loading: true});
        HttpCourseRequest().post("get-courses", {
            key_apiwi_course: window.key_access_course
        }).then(res => {
            if (res.data.status) {
                this.setState({courses:res.data.courses,loading: false});
            }
        });
    }
    getChapters()
    {
        this.setState({loading: true});
        HttpCourseRequest().post("get-chapters", {
            key_apiwi_course: window.key_access_course
        }).then(res => {
            if (res.data.status) {
                this.setState({chapters:res.data.courses,loading: false});
            }
        });
    }
    getlessons()
    {
        this.setState({loading: true});
        HttpCourseRequest().post("get-lessons", {
            key_apiwi_course: window.key_access_course
        }).then(res => {
            if (res.data.status) {
                this.setState({lessons:res.data.lessons,loading: false});
            }
        });
    }
    getUsers()
    {
        this.setState({loading: true});
        HttpCourseRequest().post("get-users", {
            key_apiwi_course: window.key_access_course
        }).then(res => {
            if (res.data.status) {
                this.setState({users:res.data.users,loading: false});
            }
        });
    }
    getChapters()
    {
        HttpCourseRequest().post("get-chapters", {
            key_apiwi_course: window.key_access_course
        }).then(res => {
            if (res.data.status) {
                this.setState({chapters:res.data.chapters});
            }
        });
    }

    getTableCourses(el, index){
        return (
            <tr>
                <td>
                    <span className="text-muted">
                        {el.id}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                        {el.name}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                        {el.duration}
                    </span>
                </td>
                <td className="table-actions">
                    <span className="text-muted" title={el.created_at}>{moment(el.created_at).fromNow()}</span>
                </td>
                <td>
                    <div>
                        <Button className={'btn btn-sm btn-outline-info'} title={'Edit Course'} onClick={e => {
                        this.setState({
                            index_selection: index,update_course: true,
                            name_course: this.state.courses[index].name, url_image: this.state.courses[index].image,
                            description_course: this.state.courses[index].description,duration_course: this.state.courses[index].duration,
                            modalCourse: true});e.preventDefault();}}><i className="fas fa-edit fa-lg"></i></Button>
                        <Button className={'btn btn-sm btn-outline-danger'} title={'Delete Course'} onClick={e => {this.deleteCourse(el.uuid);e.preventDefault();}}><i className="fas fa-trash-alt fa-lg"></i></Button>
                    </div>
                </td>
            </tr>
        );
    }
    getTableUsers(el, index){
        return (
            <tr>
                {/* <td>
                    <Badge color={""} className="badge-dot mr-4">
                        <i className={"bg-"+(el.status ? "success" : 'warning')} />
                        <span className="status">{(el.status ? "True" : 'False')}</span>
                    </Badge>
                </td> */}
                <td>
                    <span className="text-muted">
                        {el.id}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                        {el.name}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                        {el.email}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                        ******<Button className={'btn btn-sm btn-outline-dark'} title={'Copy Password'} onClick={e => {navigator.clipboard.writeText(el.text_password);}}><i className="far fa-clipboard fa-lg"></i></Button>
                    </span>
                </td>
                <td className="table-actions">
                    <span className="text-muted" title={el.created_at}>{moment(el.created_at).fromNow()}</span>
                </td>
                <td className="table-actions">
                    <span className="text-muted" title={el.updated_at}>{moment(el.updated_at).fromNow()}</span>
                </td>
                <td>
                    <div>
                        <Button className={'btn btn-sm btn-outline-success'} title={'Copy Email'} onClick={e => {navigator.clipboard.writeText(el.email);}}><i className="far fa-clipboard fa-lg"></i></Button>
                        <Button className={'btn btn-sm btn-outline-info'} title={'Edit Course'} onClick={e => {
                        this.setState({
                            index_selection: index,update_user: true,
                            name_user: this.state.users[index].name, email_user: this.state.users[index].email,
                            password_user: this.state.users[index].text_password,
                            modalUser: true});e.preventDefault();}}><i className="fas fa-edit fa-lg"></i></Button>
                        <Button className={'btn btn-sm btn-outline-danger'} title={'Delete Page'} onClick={e => {this.deleteUser(el.id);e.preventDefault();}}><i className="fas fa-trash-alt fa-lg"></i></Button>
                    </div>
                </td>
            </tr>
        );
    }

    getTableChapters(el, index)
    {
        return (
            <tr>
                <td>
                    <span className="text-muted">
                        {el.id}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                        {el.course}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                        {el.title}
                    </span>
                </td>
                <td className="table-actions">
                    <span className="text-muted" title={el.created_at}>{moment(el.created_at).fromNow()}</span>
                </td>
                <td>
                
                
                
                    <div>
                    <Button className={'btn btn-sm btn-outline-info'} title={'Edit Course'} onClick={e => {
                        this.setState({
                            index_selection: index,update_chapter: true,
                            name_chapter: this.state.chapters[index].title,description_chapter: this.state.chapters[index].description,
                            course_chapter: this.state.chapters[index].course,
                            modalChapter: true});e.preventDefault();}}><i className="fas fa-edit fa-lg"></i></Button>
                        <Button className={'btn btn-sm btn-outline-danger'} title={'Delete Chapter'} onClick={e => {this.deleteChapter(el.id);e.preventDefault();}}><i className="fas fa-trash-alt fa-lg"></i></Button>
                    </div>
                </td>
            </tr>
        );
    }
    getTableLessons(el,index)
    {
        return (
            <tr>
                <td>
                    <span className="text-muted">
                        {index+1}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                        {el.chapter}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                        {el.title}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                        {el.url_video}
                    </span>
                </td>
                <td className="table-actions">
                    <span className="text-muted" title={el.created_at}>{moment(el.created_at).fromNow()}</span>
                </td>
                <td>
                    <div>
                    <Button className={'btn btn-sm btn-outline-info'} title={'Edit Lesson'} onClick={e => {
                        this.setState({
                            index_selection: index,update_lesson: true,
                            modalLession:true,title_lession: this.state.lessons[index].title,
                            description_lession: this.state.lessons[index].description,chapter_lession: this.state.lessons[index].chapter,
                            url_lession: this.state.lessons[index].url_video,order_lession: this.state.lessons[index].order});e.preventDefault();}}><i className="fas fa-edit fa-lg"></i></Button>
                        <Button className={'btn btn-sm btn-outline-danger'} title={'Delete Lesson'} onClick={e => {this.deleteLesson(el.uuid);e.preventDefault();}}><i className="fas fa-trash-alt fa-lg"></i></Button>
                    </div>
                </td>
            </tr>
        );
    }

    /* Operation Deleted From DB */
    async deleteCourse(id)
    {
       /* eslint-disable no-restricted-globals */
        if (confirm("Are you sure you want to delete this item from the database?")) {
            HttpCourseRequest().delete("remove-course/"+id, {
                params: {
                    key_apiwi_course: window.key_access_course
                }                
            }).then(res => {
                if (res.data.status) {
                    alert(res.data.message);
                    this.getCourses();
                } else {
                    alert(res.data.message);
                }
            })
        }
        /* eslint-enable no-restricted-globals */
    }
    async deleteUser(id)
    {
       /* eslint-disable no-restricted-globals */
        if (confirm("Are you sure you want to delete this item from the database?")) {
            HttpCourseRequest().delete("remove-user/"+id, {
                params: {
                    key_apiwi_course: window.key_access_course
                }                
            }).then(res => {
                if (res.data.status) {
                    alert(res.data.message);
                    this.getUsers();
                } else {
                    alert(res.data.message);
                }
            })
        }
        /* eslint-enable no-restricted-globals */
    }
    async deleteChapter(id)
    {
       /* eslint-disable no-restricted-globals */
        if (confirm("Are you sure you want to delete this item from the database?")) {
            HttpCourseRequest().delete("remove-chapter/"+id, {
                params: {
                    key_apiwi_course: window.key_access_course
                }                
            }).then(res => {
                if (res.data.status) {
                    alert(res.data.message);
                    this.getChapters();
                } else {
                    alert(res.data.message);
                }
            })
        }
        /* eslint-enable no-restricted-globals */
    }
    async deleteLesson(id)
    {
       /* eslint-disable no-restricted-globals */
        if (confirm("Are you sure you want to delete this item from the database?")) {
            HttpCourseRequest().delete("remove-lesson/"+id, {
                params: {
                    key_apiwi_course: window.key_access_course
                }                
            }).then(res => {
                if (res.data.status) {
                    alert(res.data.message);
                    this.getlessons();
                } else {
                    alert(res.data.message);
                }
            })
        }
        /* eslint-enable no-restricted-globals */
    }
    /* Operation Save & Update In DB */
    async saveCourse() {
        if (this.state.update_course) {
            HttpCourseRequest().post("update-course/" + this.state.courses[this.state.index_selection].uuid, {
                key_apiwi_course: window.key_access_course,
                name: this.state.name_course ?? "",
                description: this.state.description_course ?? "",
                duration: this.state.duration_course ?? "",
                url_image: this.state.url_image ?? "",
            }).then(res => {
                if (res.data.status) {
                    alert(res.data.message);
                    this.setState({modalCourse:false,name_course: "",
                        description_course: "", duration_course: "",url_image: ""});
                    this.getCourses();
                } else {
                    alert(res.data.message);
                }
            });
        } else {
            HttpCourseRequest().post("add-course", {
                key_apiwi_course: window.key_access_course,
                name: this.state.name_course ?? "",
                description: this.state.description_course ?? "",
                duration: this.state.duration_course ?? "",
                url_image: this.state.url_image ?? "",
            }).then(res => {
                if (res.data.status) {
                    alert(res.data.message);
                    this.setState({modalCourse:false,name_course: "",
                        description_course: "", duration_course: "",url_image: ""});
                    this.getCourses();
                } else {
                    alert(res.data.message);
                }
            });
        }
        
    }
    async saveChapter()
    {
        if (this.state.update_chapter) {
            HttpCourseRequest().post("update-chapter/" + this.state.chapters[this.state.index_selection].id, {
                key_apiwi_course: window.key_access_course,
                title: this.state.name_chapter ?? "",
                description: this.state.description_chapter ?? "",
                course_id: this.state.course_chapter ?? ""
            }).then(res => {
                if (res.data.status) {
                    alert(res.data.message);
                    this.setState({modalChapter:false,name_chapter: "",
                        description_chapter: "", course_chapter: ""});
                    this.getChapters();
                } else {
                    alert(res.data.message);
                }
            });
        } else {
            HttpCourseRequest().post("add-new-chapter", {
                key_apiwi_course: window.key_access_course,
                title: this.state.name_chapter ?? "",
                description: this.state.description_chapter ?? "",
                course_id: this.state.course_chapter ?? ""
            }).then(res => {
                if (res.data.status) {
                    alert(res.data.message);
                    this.setState({modalChapter:false,name_chapter: "",
                        description_chapter: "", course_chapter: ""});
                    this.getChapters();
                } else {
                    alert(res.data.message);
                }
            });
        }
    }
    async saveLession()
    {
        if (this.state.update_lesson) {
            HttpCourseRequest().post("update-lesson/"+this.state.lessons[this.state.index_selection].uuid, {
                key_apiwi_course: window.key_access_course,
                title: this.state.title_lession ?? "",
                description: this.state.description_lession ?? "",
                chapter_id: this.state.chapter_lession ?? "",
                url_video: this.state.url_lession ?? "",
                order: this.state.order_lession ?? "",
            }).then(res => {
                if (res.data.status) {
                    alert(res.data.message);
                } else {
                    alert(res.data.message);
                }
            });
        } else {
            HttpCourseRequest().post("add-new-lesson", {
                key_apiwi_course: window.key_access_course,
                title: this.state.title_lession ?? "",
                description: this.state.description_lession ?? "",
                chapter_id: this.state.chapter_lession ?? "",
                url_video: this.state.url_lession ?? "",
                order: this.state.order_lession ?? "",
            }).then(res => {
                if (res.data.status) {
                    alert(res.data.message);
                    this.setState({modalLession:false,title_lession: "",
                        description_lession: "",chapter_lession: "",
                        url_lession: "",order_lession: ""});
                } else {
                    alert(res.data.message);
                }
            });
        }
    }

    saveUser()
    {
        if (this.state.update_user) {
            HttpCourseRequest().post("update-user/"+this.state.users[this.state.index_selection].id, {
                key_apiwi_course: window.key_access_course,
                name: this.state.name_user ?? "",
                email: this.state.email_user ?? "",
                password: this.state.password_user ?? "",
            }).then(res => {
                if (res.data.status) {
                    alert(res.data.message);
                    this.getUsers();
                    this.setState({modalUser: false});
                } else {
                    alert(res.data.message);
                }
            });
        } else {
            HttpCourseRequest().post("add-new-user", {
                key_apiwi_course: window.key_access_course,
                name: this.state.name_user ?? "",
                email: this.state.email_user ?? "",
                password: this.state.password_user ?? "",
            }).then(res => {
                if (res.data.status) {
                    alert(res.data.message);
                    this.setState({modalUser:false,name_user: "",
                        email_user: "",password_user: ""});
                        this.getUsers();
                } else {
                    alert(res.data.message);
                }
            });
        }
    }
    getModal(){
        return (
            /* Course / Chapter / Lession  / User */
            <>
                <Modal className="modal-dialog-centered"
                    isOpen={this.state.modalCourse}
                    toggle={() => this.setState({modalCourse:false})}>
                    <div className={"modal-content bg-gradient-primary"} style={{}}>
                        <Card className="bg-transparent">
                            <CardHeader className="modal-header">
                                <h6 className="modal-title" id="modal-title-default">
                                    {
                                        this.state.update_course ? "Update Course" : "Add New Course"
                                    }
                                </h6>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() =>this.setState({modalCourse:false})}>
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </CardHeader>
                            <CardBody>
                                <div className="mt-4">
                                    <Form className="form-primary" role="form" onSubmit={(e) => {this.saveCourse();e.preventDefault(); return false;}}>
                                        <FormGroup>
                                            <Input placeholder="Name Course *"
                                                type="text"
                                                value={this.state.name_course}
                                                onChange={e => {this.setState({name_course:e.target.value, err: ""})}}
                                            required/>
                                        </FormGroup>
                                        <FormGroup>
                                            <InputGroup>
                                                <textarea placeholder="description Course *" className='form-control' rows="4" value={this.state.description_course} onChange={e => this.setState({description_course: e.target.value})} required>
                                                </textarea>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                placeholder="duration Course (Optional ex:04:50:03)"
                                                type="text"
                                                value={this.state.duration_course}
                                                onChange={e => {this.setState({duration_course:e.target.value})}}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                placeholder="URL Image *"
                                                type="text"
                                                value={this.state.url_image}
                                                onChange={e => {this.setState({url_image:e.target.value})}}
                                            required="required"/>
                                        </FormGroup>
                                        <Button block color="info" type="submit">
                                            {
                                                this.state.update_course ? "Update Course" : "Save new Course"
                                            }
                                        </Button>
                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </Modal>
            
                <Modal className="modal-dialog-centered"
                    isOpen={this.state.modalChapter}
                    toggle={() => this.setState({modalChapter:false})}>
                    <div className={"modal-content bg-gradient-primary"}>
                        <Card className="bg-transparent">
                            <CardHeader className="modal-header">
                                <h6 className="modal-title" id="modal-title-default">
                                    {
                                        this.state.update_chapter ? "Update Chapter" : "Add New Chapter"
                                    }
                                </h6>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() =>this.setState({modalChapter:false})}>
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </CardHeader>
                            <CardBody>
                                <div className="mt-4">
                                    <Form className="form-primary" role="form" onSubmit={(e) => {this.saveChapter();e.preventDefault(); return false;}}>
                                        <FormGroup>
                                            <Input
                                                placeholder="Name chapter *"
                                                type="text"
                                                value={this.state.name_chapter}
                                                onChange={e => {this.setState({name_chapter:e.target.value, err: ""})}}
                                            required/>
                                        </FormGroup>
                                        <FormGroup>
                                            <InputGroup>
                                                <textarea placeholder="description Chapter *" className='form-control' rows="4" value={this.state.description_chapter} onChange={e => this.setState({description_chapter: e.target.value})} required>
                                                </textarea>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <label className="form-control-label text-white"
                                                htmlFor="exampleDatepicker">
                                                Select Course
                                            </label>
                                            <Select2
                                                className="form-control"
                                                defaultValue={this.state.course_chapter}
                                                onChange={(e) => {
                                                    this.setState({ course_chapter: e.target.value});
                                                }}
                                                options={{
                                                    placeholder: "Select Course"
                                                }}
                                                data={
                                                    this.state.courses.map(el => {
                                                        return { id: el.uuid, text: el.name };
                                                    })
                                                }
                                                
                                            required/>
                                        </FormGroup>
                                        <Button block color="info" type="submit">
                                            {
                                                this.state.update_chapter ? "Update Chapter" : "Save new Chapter"
                                            }
                                        </Button>
                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </Modal>

                <Modal className="modal-dialog-centered"
                    isOpen={this.state.modalLession}
                    toggle={() => this.setState({modalLession:false})}>
                    <div className={"modal-content bg-gradient-primary"} style={{}}>
                        <Card className="bg-transparent">
                            <CardHeader className="modal-header">
                                <h6 className="modal-title" id="modal-title-default">
                                    {
                                        this.state.update_lesson ? "Update Lesson" : "Add New Lesson"
                                    }
                                </h6>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() =>this.setState({modalLession:false})}>
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </CardHeader>
                            <CardBody>
                                <div className="mt-4">
                                    <Form className="form-primary" role="form" onSubmit={(e) => {this.saveLession();e.preventDefault(); return false;}}>
                                        <FormGroup>
                                            <Input
                                                placeholder="Title Vedio *"
                                                type="text"
                                                value={this.state.title_lession}
                                                onChange={e => {this.setState({title_lession:e.target.value, err: ""})}}
                                            required/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                placeholder="URL Vedio *"
                                                type="text"
                                                value={this.state.url_lession}
                                                onChange={e => {this.setState({url_lession:e.target.value, err: ""})}}
                                            required/>
                                        </FormGroup>
                                        <FormGroup>
                                            <InputGroup>
                                                <textarea placeholder="description Vedio (Optional)" className='form-control' rows="4" value={this.state.description_lession} onChange={e => this.setState({description_lession: e.target.value})} required>
                                                </textarea>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <label className="form-control-label text-white"
                                                htmlFor="exampleDatepicker">
                                                Select Chapter
                                            </label>
                                            <Select2
                                                className="form-control"
                                                defaultValue={this.state.chapter_lession}
                                                onChange={(e) => {
                                                    this.setState({ chapter_lession: e.target.value});
                                                }}
                                                options={{
                                                    placeholder: "Select Chapter"
                                                }}
                                                data={
                                                    this.state.chapters.map(el => {
                                                        return { id: el.id, text: el.title };
                                                    })
                                                }
                                            required/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                placeholder="Order Vedio (Optional) default:1"
                                                type="number"
                                                step="1"
                                                min="1"
                                                value={this.state.order_lession}
                                                onFocus={e => this.setState({order_lession: ""})}
                                                onChange={e => {this.setState({order_lession:e.target.value, err: ""})}}
                                            />
                                        </FormGroup>
                                        <Button block color="info" type="submit">
                                            {
                                                this.state.update_lesson ? "Update Lesson" : "Save new Lession"
                                            }
                                        </Button>
                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </Modal>

                <Modal className="modal-dialog-centered"
                    isOpen={this.state.modalUser}
                    toggle={() => this.setState({modalUser:false})}>
                    <div className={"modal-content bg-gradient-primary"} style={{}}>
                        <Card className="bg-transparent">
                            <CardHeader className="modal-header">
                                <h6 className="modal-title" id="modal-title-default">
                                    {
                                        this.state.update_user ? "Update User" : "AAdd New User"
                                    }
                                </h6>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() =>this.setState({modalUser:false})}>
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </CardHeader>
                            <CardBody>
                                <div className="mt-4">
                                    <Form className="form-primary" role="form" onSubmit={(e) => {this.saveUser();e.preventDefault(); return false;}}>
                                        <FormGroup>
                                            <Input
                                                placeholder="Fullname user *"
                                                type="text"
                                                value={this.state.name_user}
                                                onChange={e => {this.setState({name_user:e.target.value, err: ""})}}
                                            required/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                placeholder="E-mail User *"
                                                type="email"
                                                value={this.state.email_user}
                                                onChange={e => {this.setState({email_user:e.target.value, err: ""})}}
                                            required/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                placeholder="Password"
                                                type="text"
                                                value={this.state.password_user}
                                                onChange={e => {this.setState({password_user:e.target.value, err: ""})}}
                                            required/>
                                        </FormGroup>
                                        <Button block color="info" type="submit">
                                            {
                                                this.state.update_user ? "Update User" : "Save new User"
                                            }
                                        </Button>
                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </Modal>
            </>
        );
    }
}