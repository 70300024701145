/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container, DropdownToggle, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal,
    Pagination, PaginationItem, PaginationLink,
    Row,
    Table
} from "reactstrap";
import moment from "moment";
import Loader from "react-loader-spinner";
import {HttpConfig} from "../../tools/Http";
import classnames from "classnames";

export default class apps extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apps:[],
            loading: true,
            showAddApp: false,
            appToHide: null,
            //pagination
            current_page: null,
            first_page_url: null,
            last_page_url: null,
            next_page_url: null,
            prev_page_url: null,
            last_page: null,
            total: null,
            per_page: null
        };


    }

    componentDidMount() {
        this.getJobs('failed');
    }

    render() {
        return (
            <>
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    {this.mutableTable()}
                </Container>
            </>        )
    }

    getHeader(){
        return (
            <>
                <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
                    <Container fluid>
                        <div className="header-body">
                            <Row className="align-items-center py-4">
                                <Col lg="6" xs="7">
                                    <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                                        {this.props.name}
                                    </h6>{" "}
                                    <Breadcrumb
                                        className="d-none d-md-inline-block ml-lg-4"
                                        listClassName="breadcrumb-links breadcrumb-dark"
                                    >
                                        <BreadcrumbItem>
                                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                                <i className="fas fa-home" />
                                            </a>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem>
                                            <a href="#pablo" onClick={e => e.preventDefault()} >
                                                {"Hidden Apps"}
                                            </a>
                                        </BreadcrumbItem>
                                        {/*<BreadcrumbItem aria-current="page" className="active">*/}
                                        {/*    {this.props.name}*/}
                                        {/*</BreadcrumbItem>*/}
                                    </Breadcrumb>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        );
    }

    mutableTable(){
        return(
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            {this.state.loading ?
                                <Loader
                                    type="Puff"
                                    color="#5E72E4"
                                    height={30}
                                    width={30}

                                />:
                                <h3 className="mb-0 text-red">Hidden Apps</h3>
                            }
                        </Col>
                        <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                            <Button className="" color={'danger'} size="sm" onClick={() =>
                            {this.setState({showAddApp:true})}}>
                                Hide app
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                        <th>#</th>
                        <th>App</th>
                        <th>id</th>
                        <th>created at</th>
                    </tr>
                    </thead>
                    <tbody className="list">
                    {this.state.apps.map ? this.state.apps.map(el => {
                        return (
                            this.getUserRow(el)
                        )
                    }) : Object.keys(this.state.apps).map(el => {
                        return (
                            this.getUserRow(this.state.apps[el])
                        )
                    }) }

                    </tbody>
                </Table>
                {this.state.apps.length === 0 && <div style={{height:400}}/>}

                {this.state.first_page_url &&
                <CardFooter className="py-4">
                    <nav aria-label="...">
                        <Pagination
                            className="pagination justify-content-end mb-0"
                            listClassName="justify-content-end mb-0"
                        >
                            <PaginationItem >
                                <PaginationLink
                                    href="#"
                                    onClick={e => {this.getJobs(this.state.first_page_url);e.preventDefault()}}
                                    tabIndex="-1"
                                >
                                    <i className="fas fa-angle-double-left" />
                                    <span className="sr-only">Previous</span>
                                </PaginationLink>
                            </PaginationItem>
                            {this.state.prev_page_url &&
                            <PaginationItem>
                                <PaginationLink
                                    href="#"
                                    onClick={e => {this.getJobs(this.state.prev_page_url);e.preventDefault()}}
                                >
                                    {this.state.current_page-1}
                                </PaginationLink>
                            </PaginationItem>
                            }
                            <PaginationItem className="active">
                                <PaginationLink
                                    href="#"
                                    onClick={e => e.preventDefault()}
                                >
                                    {this.state.current_page}
                                </PaginationLink>
                            </PaginationItem>
                            {this.state.next_page_url &&
                            <PaginationItem>
                                <PaginationLink
                                    href="#"
                                    onClick={e => {this.getJobs(this.state.next_page_url);e.preventDefault()}}
                                >
                                    {this.state.current_page+1} <span className="sr-only">(current)</span>
                                </PaginationLink>
                            </PaginationItem>
                            }
                            <PaginationItem>
                                <PaginationLink
                                    href="#"
                                    onClick={e => {this.getJobs(this.state.last_page_url);e.preventDefault()}}
                                >
                                    <i className="fas fa-angle-double-right" />
                                    <span className="sr-only">Next</span>
                                </PaginationLink>
                            </PaginationItem>
                        </Pagination>
                    </nav>
                </CardFooter>
                }
                {this.renderAddApp()}
            </Card>
        );
    }



    getJobs(param){
        this.setState({apps:[],loading:true});
        HttpConfig().get('apps/hidden')
            .then(res => {
                if (res.data.status) {
                    this.setState({
                        apps:res.data.apps ? res.data.apps : [],
                        loading:false,
                        first_page_url: this.strip(res.data.apps.first_page_url),
                        last_page_url: this.strip(res.data.apps.last_page_url),
                        next_page_url: this.strip(res.data.apps.next_page_url),
                        prev_page_url: this.strip(res.data.apps.prev_page_url),
                        current_page: res.data.apps.current_page,
                        last_page: res.data.apps.last_page,
                        total: res.data.apps.total,
                        per_page: res.data.apps.per_page
                    });
                }
                else {
                    this.setState({loading:false})
                }
            }).catch(err => {
            //err
            this.setState({loading:false})
        })
    }

    getUserRow(el){
        return (
            <tr>
                <td>
                    <span className="text-muted">
                            {el.id}
                    </span>
                </td>
                <td className="table-user">
                    <img
                        alt="..."
                        className="avatar rounded-circle mr-3"
                        src={el.icon}
                    />
                    <b>{el.name}</b>
                </td>
                <td>
                    <span className="text-muted">
                        {el.app_id}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                        {el.created_at ? moment(el.created_at).format('MMM DD YYYY hh:mm') : '---'}
                    </span>
                </td>
            </tr>
        );
    }

    strip(link){
        if(!link)
            return null;
        link = link.split('/super/apps/');
        return link[link.length-1];
    }

    renderAddApp(){
        return (
            <Modal
                className="modal-dialog-centered"
                isOpen={this.state.showAddApp}
                toggle={() => this.setState({showAddApp:false})}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        Assign Card to User
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() =>this.setState({showAddApp:false})}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col md="10">
                            <FormGroup>
                                <InputGroup className={classnames("input-group-merge", {
                                        focused: this.state.location
                                    })}>
                                    <Input
                                        placeholder="Package Name"
                                        type="text"
                                        value={this.state.appToHide}
                                        onFocus={e => this.setState({ location: true, appToHide: "" })}
                                        onBlur={e => this.setState({ location: false, err: "" })}
                                        onChange={e => {this.setState({appToHide:e.target.value, err: ""})}}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <i className="fas fa-envelope-o" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>

                                {this.state.err !== '' && <p className={"text-danger"}>{this.state.err}</p>}
                                {this.state.succ !== '' && <p className={"text-success"}>{this.state.succ}</p>}
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <Button
                        color="danger"
                        role="button"
                        onClick={e => {this.hideApp()}}
                    >
                        Hide
                    </Button>
                    <Button className="ml-auto disabled"
                        color="link"
                        data-dismiss="modal" type="button"
                        onClick={() => {this.setState({showAddApp:false})} }>
                        Close
                    </Button>
                </div>
            </Modal>)
    }
    hideApp(){
        HttpConfig().post('apps/hidden', {
            app_id:this.state.appToHide
        })
        .then(res => {
            console.log(res);
            if (res.data.status) {
                this.setState({showAddApp:false})
                this.getJobs()
            }
            this.setState({err: res.data.message});
        })
        .catch(err => {})
    }
}
