import axios from 'axios';
import {GetToken} from "./JwtToken";

export function HttpConfig() {
    let headers = {};

    const token = GetToken();
    headers = {'Authorization': 'Bearer '+token};
    const axiosInstance = axios.create({
        baseURL: window.api_url,
        // baseURL: 'http://164.90.236.47/api/super/',
        // timeout: 5000,
        headers: headers
    });

    return axiosInstance;
}

export function HttpCourseRequest()
{
    const axiosInstance = axios.create({
        baseURL: window.api_course,
    });

    return axiosInstance;
}

